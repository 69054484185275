import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SIGNIN_USER, SIGNOUT_USER, GET_USER } from 'constants/ActionTypes';
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  setReduxAuthenticated2FA,
  hideAuthLoader,
  hideMessage,
} from 'actions/Auth';

import { resetFiltersRedux } from 'actions/Filters';

import { getIdUser, getHashEmpresa, getToken, encrypt } from 'util/cobUtils';

import api from 'services/api';
import api2 from 'services/api2';

const signInUserWithEmailPasswordRequest = async (
  conta,
  username,
  password,
  ip
) =>
  api2
    .post('/login', { conta, username, password, ip })
    .then((authUser) => authUser)
    .catch((error) => error);

const getUsuarioLogadoRequest = async (idHashEmp, uuidUsuario) =>
  api
    .post('/usuario_logado', { idHashEmp, uuidUsuario })
    .then((authUser) => authUser)
    .catch((error) => error);
/*
const signOutRequest = async () =>
  await auth
    .signOut()
    .then((authUser) => authUser)
    .catch((error) => error);
*/

// AUTENTICACAO AQUI
function* signInUserWithEmailPassword({ payload }) {
  const { conta, username, password, ip } = payload;
  try {
    if (conta && username && password) {
      const retornoAuth = yield call(
        signInUserWithEmailPasswordRequest,
        conta,
        username,
        password,
        ip
      );

      if (retornoAuth.message) {
        if (retornoAuth.response.status == 401)
          yield put(showAuthMessage('Usuário e/ou Senha Incorretos.'));
        else yield put(showAuthMessage(retornoAuth.response.data));
      } else if (retornoAuth.request.status == 201) {
        let acesso = retornoAuth.data;
        acesso = JSON.stringify(acesso, undefined, '');
        acesso = encrypt(acesso);

        yield put(resetFiltersRedux());
        yield put(setReduxAuthenticated2FA(retornoAuth.data));
      } else {
        let acesso = retornoAuth.data;
        acesso = JSON.stringify(acesso, undefined, '');
        acesso = encrypt(acesso);
        localStorage.setItem('cobcloud_user', retornoAuth.data.id_uuid);
        localStorage.setItem('cobcloud_token', retornoAuth.data.token);
        localStorage.setItem('cobcloud_token2', retornoAuth.data.codemp);
        localStorage.setItem('access', acesso);
        yield put(resetFiltersRedux());
        yield put(userSignInSuccess(retornoAuth.data));
      }

      yield put(hideAuthLoader());
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

// PREENCHER NO REDUX O USUARIO LOGADO
function* getUsuarioLogado() {
  const idHashEmp = getHashEmpresa();
  const idHashUser = getIdUser();

  try {
    if (idHashEmp != '' && idHashUser != '') {
      const retornoAuth = yield call(
        getUsuarioLogadoRequest,
        idHashEmp,
        idHashUser
      );

      yield put(userSignInSuccess(retornoAuth.data));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    localStorage.removeItem('cobcloud_user');
    localStorage.removeItem('user_menu');
    localStorage.removeItem('cobcloud_token');
    localStorage.removeItem('cobcloud_token2');
    localStorage.removeItem('access');
    yield put(userSignOutSuccess(signOutUser));
    yield put(hideMessage());
    yield put(hideAuthLoader());
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export function* userLogado() {
  yield takeEvery(GET_USER, getUsuarioLogado);
}

export default function* rootSaga() {
  yield all([fork(signInUser), fork(signOutUser), fork(userLogado)]);
}
