import React, { useEffect, useState, useCallback, useMemo  } from 'react';

import PropTypes from 'prop-types';
import Moment from 'moment';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CommentIcon from '@material-ui/icons/Comment';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Button,
  Tooltip,
  MenuItem,
  Menu,
  Box,
  Typography,
  IconButton,
  Divider,
  ListItemIcon,
  ListItemText,
  Fade,
} from '@material-ui/core';
import Loading from 'components/Utils/loading';
import { useSnackbar } from 'notistack';
import { formatMoney, getHashEmpresa } from 'util/cobUtils';
import api from 'services/api';

import { URL_ALEGA } from 'constants/DiversosConst';

import VisualizarObservacao from './dialogs/Observacao';
import VisualizarOcorrencia from './dialogs/Ocorrencia';
import RejeitarAlega from './dialogs/Rejeitar';
import copy from 'copy-to-clipboard';
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import LancarRecebimento from './dialogs/LancarRecebimento';
import EstornarRecebimento from './dialogs/EstornarRecebimento';
import CancelarPagamento from './dialogs/CancelarPagamento';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CancelIcon from '@mui/icons-material/Cancel';
import CustomDataGridPro from 'components/CustomDataGridPro';
import { min } from 'date-fns';

const themeReactSelect = (theme) => ({
  ...theme,
  spacing: {
    ...theme.spacing,
    controlHeight: 40,
    // baseUnit: 0,
  },
  colors: {
    ...theme.colors,
    primary: '#28a745',
  },
});


const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    marginTop: '5px',
    width: '250px',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const DataTableHead = (props) => {
  const createSortHandler = (property) => {
    props.onRequestSort(property);
  };

  const { order, orderBy, columnData } = props;

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell align="center">Ações</StyledTableCell>

        {columnData.map((column) => {
          return (
            <StyledTableCell
              key={column.id}
              align={column.align}
              padding={column.disablePadding ? 'normal' : 'checkbox'}
              sortDirection={orderBy === column.orderBy ? order : false}
            >
              {column.orderBy !== '' ? (
                <Tooltip
                  title={`Ordenar por ${column.label}`}
                  placement={
                    column.align == 'left' ? 'bottom-end' : 'bottom-start'
                  }
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.orderBy}
                    direction={order}
                    onClick={() => createSortHandler(column.orderBy)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              ) : (
                <div>{column.label}</div>
              )}
            </StyledTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
const coresStatus = {
  pendente: {
    descricao: 'pendente',
    cor: '#3f51b5',
  },
  confirmado: {
    descricao: 'confirmado',
    cor: '#3eba2b',
  },
  rejeitado: {
    descricao: 'rejeitado',
    cor: '#e8372a',
  },
  retorno: {
    descricao: 'retorno',
    cor: '#FF4081',
  },
  baixado: {
    descricao: 'baixado',
    cor: '#e8eaf6',
  },
  cancelado: {
    descricao: 'cancelado',
    cor: '#e8372a',
  },
};

function getColorByStatus(status) {
  const statusTratado = (status || '').toLowerCase();
  const corStatusLocalizado = Object.values(coresStatus).find(
    (item) => item.descricao === statusTratado
  );
  return corStatusLocalizado ? corStatusLocalizado.cor : '#3f51b5'; // Default color
}




DataTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles(() => ({
  container: {
    overflowY: 'hidden',
  },
  iconbutton_grid: {
    margin: 0,
    marginRight: '10px',
    padding: 1,
  },
  listItemText: {
    fontSize: '12px',
  }
}));

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#F5F5F5',
    fontSize: '12px',
    padding: 2,
    whiteSpace: 'nowrap',
  },
  body: {
    fontSize: '12px',
    padding: 7,
    whiteSpace: 'nowrap',
  },
}))(TableCell);

export default function AlegaPagamento(props) {
  const columnData = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Ações',
      resizable: false,
      hideable: false,
      getActions: ({ row }) => [
        <>
          <OpcoesIcon row={row} />
        </>
      ]
    },
    {
      align: 'left',
      disablePadding: true,
      hidden: false,
      orderBy: 'alega.processo',
      minWidth: 100,
      headerName: 'Processo',
      field: 'processo',
      renderCell: ({ row }) => (
        <>
          <Link target="_blank"
           to={`/app/cob/processo/${row.id_uuid_devedor}`}>
            {row.processo}
          </Link>
        </>
      ),
    },
    {
      align: 'left',
      disablePadding: true,
      hidden: false,
      orderBy: 'alega.data_cad_br',
      minWidth: 70,
      headerName: 'Data',
      field: 'data_cad_br',
    },
    {
      align: 'left',
      disablePadding: true,
      hidden: false,
      orderBy: 'c.razaosocial',
      minWidth: 220,
      headerName: 'Carteira / Credor',
      field: 'credor',
    },
    {
      align: 'left',
      disablePadding: true,
      hidden: false,
      orderBy: 'd.razaosocial',
      minWidth: 180,
      headerName: 'Devedor',
      field: 'razao_dev',
    },
    {
      align: 'left',
      disablePadding: true,
      hidden: false,
      orderBy: 'd.cnpj',
      minWidth: 120,
      headerName: 'CPF / CNPJ',
      field: 'cnpj_dev',
    },
    {
      align: 'left',
      disablePadding: true,
      hidden: false,
      orderBy: 'titulos.total_titulos',
      minWidth: 100,
      headerName: 'Qtde. Títulos',
      field: 'qtde_titulos',
    },
    {
      align: 'left',
      disablePadding: true,
      hidden: false,
      orderBy: 'alega.data_envio_email',
      minWidth: 70,
      headerName: 'Data e-mail',
      field: 'data_email',
    },
    {
      align: 'center',
      disablePadding: true,
      hidden: false,
      orderBy: 'alega.status',
      minWidth: 150,
      headerName: 'Status',
      field: 'status',
      renderCell: ({ row }) => (
        <Box
          display="inline-flex"
          padding="3px 6px"
          color={row.status === 'BAIXADO' ? '#212529' : '#fff'}
          bgcolor={getColorByStatus(row.status)}
          borderRadius={12} 
          textAlign="center"
          style={{ 
            boxSizing: 'border-box', 
            maxWidth: '150px',
            overflow: 'hidden',
            textOverflow: 'ellipsis', 
          }}
        >
          <Typography component="span" style={{ fontSize: 10 }}>
            {row.status === 'RETORNO' ? 'AGUARDANDO RETORNO' : row.status}
          </Typography>
        </Box>
      ),
    },
    {
      align: 'left',
      disablePadding: true,
      hidden: false,
      orderBy: 'data_status',
      minWidth: 180,
      headerName: 'Data Status',
      field: 'data_status',
    },
  ];

  const classes = useStyles();
  const dataHojeFormat = Moment(new Date()).format('YYYY-MM-DD');

  const [tipoCredorOrGrupo, seTipoCredorOrGrupo] = useState(1);
  const [listaAlega, setListaAlega] = useState([]);
  const [loading, setLoading] = useState(false);
  const [listaCredores, setListaCredores] = useState([]);
  const [credorSelecionado, setCredorSelecionado] = useState(null);
  const [listaGrupos, setListaGrupos] = useState([]);
  const [grupoSelecionado, setGrupoSelecionado] = useState(null);
  const [tipoData, setTipoData] = useState(1);
  const [dataIni, setDataIni] = useState(dataHojeFormat);
  const [dataFin, setDataFin] = useState(dataHojeFormat);
  const [tipoBusca, setTipoBusca] = useState(1);
  const [txBusca, setTxBusca] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('alega.id');
  const [qtdeReg, setQtdeReg] = useState(0);
  const [inativarData, setInativarData] = useState(false);
  const [corDisabled, setCorDisabled] = useState('#ffffff');
  const [status, setStatus] = useState('TODOS');
  const [linhaSelecionada, setLinhaSelecionada] = useState();
  const [isVisualizarObsOpen, setIsVisualizarObsOpen] = useState(false);
  const [isOcorrenciasOpen, setIsOcorrenciasOpen] = useState(false);
  const [isRejeitarOpen, setIsRejeitarOpen] = useState(false);
  const [isLancarRecebimentoOpen, setIsLancarRecebimentoOpen] = useState(false);
  const [isEstornarRecebimentoOpen, setIsEstornarRecebimentoOpen] =
    useState(false);
  const [cancelarPagamento, setCancelarPagamento] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [enviarEmail, setEnviarEmail] = useState(false);
  const [mensagem, setMensagem] = useState(null);
  const [dadosCredor, setDadosCredor] = useState(null);
  const [dadosDevedor, setDadosDevedor] = useState(null);
  const [dadosEmail, setDadosEmail] = useState(null);
  const [hashCredor, setHashCredor] = useState('');
  const [linhasSelecionadas, setLinhasSelecionadas] = useState([]);
  const [scrollBarTop, setScrollBarTop] = useState(0);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const linkAlega = URL_ALEGA;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (paginationModel) {
      setPage(paginationModel.page);
      setRowsPerPage(paginationModel.pageSize);
    }
  }, [paginationModel]);

  useEffect(() => {
    listarCredores();
    listarGrupoCredores();
  }, []);

  useEffect(() => {
    if (linhaSelecionada && hashCredor) {
      gerarObjetoDevedor(linhaSelecionada);
      carregarCredor(hashCredor);
    }
  }, [linhaSelecionada, hashCredor]);

  useEffect(() => {
    if (dadosCredor && dadosEmail && dadosDevedor) {
      preencherModelo();
    }
  }, [dadosCredor, dadosEmail, dadosDevedor]);

  useEffect(() => {
    if (mensagem && enviarEmail) {
      handleEnviarEmailCredor();
    }
  }, [mensagem]);

  useEffect(() => {
    props.history.push(
      `portal-alega-pagamento?page=${page}&limit=${rowsPerPage}`
    );

    if (listaAlega.length > 0) setQtdeReg(parseInt(listaAlega[0].qtdereg));
    else setQtdeReg(0);
  }, [listaAlega]);

  useEffect(() => {
    if (listaAlega.length > 0) {
      listarAlegaPagamentos();
    }
  }, [page, rowsPerPage, order, orderBy]);

  const handleClickConfirmaCancelarPagamento = () => {
    setCancelarPagamento(false);
    listarAlegaPagamentos();
    setAnchorEl(null);
  };

  const abrirMenuMaisAcoes = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const fecharMenuMaisAcoes = () => {
    setAnchorEl(null);
  };

  const formatarDadosParaCancelar = dados => dados.map(item => {
    const [id, status] = typeof item === 'string' ? item.split('_') : [];
    return { id: id || null, status: status || null };
  });

  const linhasSelecionadasFormatadas = formatarDadosParaCancelar(linhasSelecionadas);
  const todasLinhasNaoCanceladas = linhasSelecionadasFormatadas.every(item => item.status !== 'CANCELADO');

  const handleSortDataGridModelChange  = useCallback((sortModel) => {
    if(sortModel.length > 0) {
      let sCampoOrdenacao = 'alega.id';
      const campoOrdenacao = sortModel[0].field;

      if (campoOrdenacao == 'processo') sCampoOrdenacao = 'alega.processo';
      else if (campoOrdenacao == 'data_cad') sCampoOrdenacao = 'alega.data_cad';
      else if (campoOrdenacao == 'razao_cre') sCampoOrdenacao = 'c.razaosocial';
      else if (campoOrdenacao == 'razao_dev') sCampoOrdenacao = 'd.razaosocial';
      else if (campoOrdenacao == 'cnpj_dev') sCampoOrdenacao = 'd.cnpj';
      else if (campoOrdenacao == 'qtde_titulos') sCampoOrdenacao = 'titulos.total_titulos';
      else if (campoOrdenacao == 'data_email') sCampoOrdenacao = 'alega.data_envio_email';
      else if (campoOrdenacao == 'status') sCampoOrdenacao = 'alega.status';
      else if (campoOrdenacao == 'data_status') sCampoOrdenacao = 'data_status';

      const ordemOrdenacao = sortModel[0].sort;
    setOrderBy(sCampoOrdenacao);
    setOrder(ordemOrdenacao);
  }
}, []);

  function gerarObjetoDevedor(row) {
    const objetoDevedor = {};
    objetoDevedor.processo = row.processo;
    objetoDevedor.devedor_cod_ext = row.cod_ext;
    objetoDevedor.end_cidade = row.end_cidade;
    objetoDevedor.end_uf = row.end_uf;
    objetoDevedor.devedor = row.razao_dev;
    objetoDevedor.devedor_cnpj = row.cnpj_dev;
    objetoDevedor.devedor_email1 = row.email1;
    objetoDevedor.devedor_email2 = row.email2;
    objetoDevedor.devedor_assunto_titulos_serem_liberados = `Liberação do Devedor - ${row.razao_dev}`;
    setDadosDevedor(objetoDevedor);
  }

  async function listarAlegaPagamentos() {
    try {
      const indexCredorOrGroupo =
        !credorSelecionado && !grupoSelecionado ? 0 : tipoCredorOrGrupo;

      const axiosConfig = {
        params: {
          limit: rowsPerPage,
          page,
          orderby: orderBy,
          order,
          grupoOrCredor: indexCredorOrGroupo,
          txGrupoOrCredor:
            indexCredorOrGroupo === 1 && credorSelecionado
              ? credorSelecionado.id_uuid
              : indexCredorOrGroupo === 2 && grupoSelecionado
                ? grupoSelecionado.nome
                : null,
          filtroData: tipoData,
          dataIni,
          dataFin,
          filtroBusca: !txBusca ? 0 : tipoBusca,
          txBusca,
          status,
        },
      };

      setLoading(true);

      const response = await api.get(`/listar_alega_pagamento`, axiosConfig);
      setListaAlega(response.data);
    } catch (error) {
      enqueueSnackbar(
        'Erro na listagem! Entre em contato com o suporte técnico.',
        { variant: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }

  async function listarCredores() {
    const hashEmpresa = getHashEmpresa();
    const response = await api.get(`/listar_credor_combobox/${hashEmpresa}`);
    const data = await response.data.rows;
    setListaCredores(data);
  }

  async function listarGrupoCredores() {
    const hashEmpresa = getHashEmpresa();
    const response = await api.get(`/listar_grupo_credores/${hashEmpresa}`);
    const data = await response.data.rows;
    setListaGrupos(data);
  }

  function alteraValorSelectData(e) {
    const dataSelect = parseInt(e.target.value);
    setTipoData(dataSelect);

    if (dataSelect === 0) {
      setInativarData(true);
      setCorDisabled('#f1f1f1');
      setDataIni(dataHojeFormat);
      setDataFin(dataHojeFormat);
    } else {
      setInativarData(false);
      setCorDisabled('#ffffff');
      setDataIni(dataHojeFormat);
      setDataFin(dataHojeFormat);
    }
  }

  const handleRequestSort = (property) => {
    const orderByNew = property;
    let orderNew = 'asc';

    if (orderBy === property) {
      if (order === 'desc') orderNew = 'asc';
      else if (order === 'asc') orderNew = 'desc';
    }
    setOrder(orderNew);
    setOrderBy(orderByNew);
  };

  const handleReenviarLink = async (row) => {
    setLoading(true);
    try {
      const data = {
        hash_alega: row.id_uuid,
        idOcorrencia: row.idocorrencia_credor,
      };
      const response = await api.post('/reenviar_link_alega', { data });
      if (response.status === 200) {
        await handleCarregarEstruturaEmail(row);
        listarAlegaPagamentos();
        return;
      }
      throw new Error('Erro ao enviar o link para o credor');
    } catch (error) {
      enqueueSnackbar('Erro ao enviar o link para o credor.', {
        variant: 'error',
      });

      setLoading(false);
    }
  };

  const handleCarregarEstruturaEmail = async (row) => {
    const response = await api.get('/carregar_dados_email');
    if (!response) return;

    setHashCredor(row.uuid_credor);
    setDadosEmail(response.data.rows[0]);
  };

  const handleEnviarEmailCredor = async () => {
    try {
      const hashEmpresa = getHashEmpresa();

      setLoading(true);
      setEnviarEmail(false);

      const {
        assunto,
        email,
        senha,
        ssl,
        tls,
        usuario,
        autenticacao,
        porta,
        host,
      } = dadosEmail;

      const corpoEmail = mensagem;

      const data = {
        assunto,
        mensagem: `${draftToHtml(
          convertToRaw(corpoEmail.getCurrentContent())
        )}`,
        remetente: {
          host,
          porta,
          autenticacao,
          usuario,
          senha,
          remetente: usuario,
          ssl,
          tls,
          email,
        },
        destinatario: linhaSelecionada.email_credor,
      };

      const resp = await api.post(`/enviar_email/${hashEmpresa}`, {
        data2: JSON.stringify(data, null, 2),
      });

      if (resp.status === 200) {
        const res = await api.get(
          `/solicitar_confirmacao/${linhaSelecionada.id_uuid}`
        );

        if (res.status === 200) {
          enqueueSnackbar('E-mail enviado com sucesso.', {
            variant: 'success',
          });

          listarAlegaPagamentos();
          return;
        }
        throw new Error('Erro ao alterar os status');
      }
    } catch (error) {
      enqueueSnackbar('Erro ao enviar o e-mail para o credor.', {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  async function carregarCredor(hashCredor) {
    if (!hashCredor) return;

    const response = await api.get(`carregar_credor/${hashCredor}`);
    const [credor] = response.data.rows;

    setDadosCredor(credor);
  }

  const formatCnpjOrCpf = (value) => {
    if (value) {
      return value.length === 11
        ? `${value.substring(0, 3)}.${value.substring(3, 6)}.${value.substring(
          6,
          9
        )}-${value.substring(9, 11)}`
        : value.length === 14
          ? `${value.substring(0, 2)}.${value.substring(2, 5)}.${value.substring(
            5,
            8
          )}/${value.substring(8, 12)}-${value.substring(12, 14)}`
          : value;
    }
    return value;
  };

  const formatCnpjOrCpfLGPD = (value) => {
    if (value) {
      return value.length === 11
        ? `***.${value.substring(3, 6)}.${value.substring(6, 9)}-**`
        : value.length === 14
          ? `**.***.${value.substring(5, 8)}/${value.substring(
            8,
            12
          )}-${value.substring(12, 14)}`
          : value;
    }
    return value;
  };

  const variavelTitulos = async () => {
    let titulos = '';

    const hashEmpresa = dadosDevedor.hash_empresa || getHashEmpresa();
    try {
      const response = await api.get(
        `/listar_titulos_devedor_aberto_email/${hashEmpresa}?idDevedor=${linhaSelecionada.uuid_devedor}`
      );
      const data = await response.data.rows;

      for (let index = 0; index < data.length; index++) {
        const element = data[index];

        const { ntitulo } = element;
        let parcela = '';
        if (element.parcela) parcela = element.parcela;
        const vencimento = element.vencimento_br;
        const valor = `R$ ${formatMoney(element.valor)}`;

        if (ntitulo) titulos += `<b>Título: </b>${ntitulo} ${parcela} `;
        titulos += `<b>- Vencimento: </b>${vencimento} `;
        titulos += `<b>- Valor: </b>${valor}`;
        titulos += `<br/>`;
      }
    } catch (error) {
      enqueueSnackbar('Erro ao carregar os títulos.', { variant: 'error' });
    }

    return titulos;
  };

  const variavelTitulosSemValor = async () => {
    let titulos = '';

    const hashEmpresa = dadosDevedor.hash_empresa || getHashEmpresa();
    try {
      const response = await api.get(
        `/listar_titulos_devedor_aberto_email/${hashEmpresa}?idDevedor=${linhaSelecionada.uuid_devedor}`
      );
      const data = await response.data.rows;

      for (let index = 0; index < data.length; index++) {
        const element = data[index];

        const { ntitulo } = element;
        let parcela = '';
        if (element.parcela) parcela = element.parcela;
        const vencimento = element.vencimento_br;

        if (ntitulo) titulos += `<b>Título: </b>${ntitulo} ${parcela} `;
        titulos += `<b>- Vencimento: </b>${vencimento}`;
        titulos += `<br/>`;
      }
    } catch (error) {
      enqueueSnackbar('Erro ao carregar os títulos.', { variant: 'error' });
    }

    return titulos;
  };

  const variavelTitulosSemAcordo = async () => {
    let titulos = '';

    const hashEmpresa = dadosDevedor.hash_empresa || getHashEmpresa();
    try {
      setLoading(true);
      const response = await api.get(
        `/listar_titulos_devedor_aberto_email/${hashEmpresa}?idDevedor=${linhaSelecionada.uuid_devedor}`
      );
      setLoading(false);
      const data = await response.data.rows;

      const elementosComAcordoFalse = data.filter(
        (element) => element.acordo === 'false'
      );

      elementosComAcordoFalse
        .map((element) => {
          const { ntitulo } = element;
          let parcela = '';

          if (element.parcela) parcela = element.parcela;
          const vencimento = element.vencimento_br;
          const valor = `R$ ${formatMoney(element.valor)}`;

          if (ntitulo)
            titulos += `<b>Título: </b>${ntitulo} <b>parcela:</b> ${parcela} `;
          titulos += `<b>- Vencimento: </b>${vencimento} `;
          titulos += `<b>- Valor: </b>${valor}`;
          titulos += `<br/>`;
        })
        .join('');

      return titulos;
    } catch (error) {
      console.log('ERRO! Erro ao carregar os títulos.');
      return '';
    }
  };

  async function preencherModelo() {
    const hashEmpresa = getHashEmpresa();
    let msgEmail = dadosEmail.mensagem;
    const dadosSubstituicaoDevedor = {
      nomeCompleto: '',
      primeiroNome: '',
      documento: '',
      documentoLGPD: '',
      codigoExterno: '',
      processo: '',
      cid_uf: '',
    };

    const dadosSubstituicaoCredor = {
      razaoSocial: '',
      fantasia: '',
      documento: '',
      documentoLGPD: '',
    };

    if (dadosDevedor || Object.values(dadosCredor).length) {
      if (dadosDevedor.devedor && dadosDevedor.devedor.includes(' ')) {
        const indexEspacoNome = dadosDevedor.devedor.indexOf(' ');

        dadosSubstituicaoDevedor.nomeCompleto = dadosDevedor.devedor;
        dadosSubstituicaoDevedor.primeiroNome = dadosDevedor.devedor.substring(
          0,
          indexEspacoNome
        );
      }

      if (dadosDevedor.devedor_cnpj) {
        const documentoFormatado = dadosDevedor.devedor_cnpj.replace(
          /[^0-9]+/g,
          ''
        );

        dadosSubstituicaoDevedor.documento =
          documentoFormatado.length === 11 || documentoFormatado.length === 14
            ? formatCnpjOrCpf(documentoFormatado)
            : documentoFormatado;

        dadosSubstituicaoDevedor.documentoLGPD =
          documentoFormatado.length === 11 || documentoFormatado.length === 14
            ? formatCnpjOrCpfLGPD(documentoFormatado)
            : documentoFormatado;
      }

      if (dadosDevedor.credor_cnpj || dadosCredor.cnpj) {
        const documentoFormatado = dadosDevedor.credor_cnpj || dadosCredor.cnpj;

        dadosSubstituicaoCredor.documento =
          documentoFormatado.length === 11 || documentoFormatado.length === 14
            ? formatCnpjOrCpf(documentoFormatado)
            : documentoFormatado;

        dadosSubstituicaoCredor.documentoLGPD =
          documentoFormatado.length === 11 || documentoFormatado.length === 14
            ? formatCnpjOrCpfLGPD(documentoFormatado)
            : documentoFormatado;
      }

      if (msgEmail && msgEmail.indexOf('[titulos_aberto]') > 0) {
        msgEmail = msgEmail.replaceAll(
          '[titulos_aberto]',
          await variavelTitulos()
        );
      }

      if (msgEmail && msgEmail.indexOf('[titulos_aberto_sem_valor]') > 0) {
        msgEmail = msgEmail.replaceAll(
          '[titulos_aberto_sem_valor]',
          await variavelTitulosSemValor()
        );
      }

      if (msgEmail.indexOf('[titulos_aberto_sem_acordo]') > 0) {
        msgEmail = msgEmail.replaceAll(
          '[titulos_aberto_sem_acordo]',
          await variavelTitulosSemAcordo()
        );
      }

      dadosSubstituicaoCredor.razaoSocial =
        dadosDevedor.credor_razao || dadosCredor.razaosocial || '';
      dadosSubstituicaoCredor.fantasia =
        dadosDevedor.credor_fantasia || dadosCredor.fantasia || '';
      dadosSubstituicaoDevedor.codigoExterno =
        dadosDevedor.devedor_cod_ext || '';
      dadosSubstituicaoDevedor.processo = dadosDevedor.processo || '';
      dadosSubstituicaoDevedor.cid_uf =
        dadosDevedor.end_cidade +
        (dadosDevedor.end_uf ? ' / ' + dadosDevedor.end_uf : '') || '';
    }

    msgEmail = msgEmail.replaceAll(
      '[credor_razao]',
      dadosSubstituicaoCredor.razaoSocial
    );

    msgEmail = msgEmail.replaceAll(
      '[credor_fantasia]',
      dadosSubstituicaoCredor.fantasia
    );

    msgEmail = msgEmail.replaceAll(
      '[credor_cnpj]',
      dadosSubstituicaoCredor.documento
    );

    msgEmail = msgEmail.replaceAll(
      '[credor_cnpj_lgpd]',
      dadosSubstituicaoCredor.documentoLGPD
    );

    msgEmail = msgEmail.replaceAll(
      '[devedor_razao]',
      dadosSubstituicaoDevedor.nomeCompleto
    );

    msgEmail = msgEmail.replaceAll(
      '[devedor_razao_primeiro_nome]',
      dadosSubstituicaoDevedor.primeiroNome
    );

    msgEmail = msgEmail.replaceAll(
      '[devedor_cnpj]',
      dadosSubstituicaoDevedor.documento
    );

    msgEmail = msgEmail.replaceAll(
      '[devedor_cid_uf]',
      dadosSubstituicaoDevedor.cid_uf
    );

    msgEmail = msgEmail.replaceAll(
      '[devedor_cnpj_lgpd]',
      dadosSubstituicaoDevedor.documentoLGPD
    );

    msgEmail = msgEmail.replaceAll(
      '[devedor_cod_ext]',
      dadosSubstituicaoDevedor.codigoExterno
    );

    msgEmail = msgEmail.replaceAll(
      '[processo]',
      dadosSubstituicaoDevedor.processo
    );

    msgEmail = msgEmail.replaceAll(
      '[link_alega_pagamento]',
      `<a href=${linkAlega}/${hashEmpresa}/${linhaSelecionada.id_uuid}>${linkAlega}/${hashEmpresa}/${linhaSelecionada.id_uuid}</a>`
    );

    setMensagem(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(msgEmail))
      )
    );
  }

  async function confirmaEstornarRecebimentoLiberado() {
    const idHash = linhaSelecionada.hash_recebimento;
    const hashAlega = linhaSelecionada.id_uuid;
    try {
      setLoading(true);
      const response = await api.put(
        `/recebimento_estornado_alega/${hashAlega}?recebimento=${idHash}`
      );
      if (response.status === 200) {
        enqueueSnackbar('Estornado com sucesso.', { variant: 'success' });
        setIsEstornarRecebimentoOpen(false);
        listarAlegaPagamentos();
      }
    } catch (error) {
      if (error.response) {
        enqueueSnackbar(
          error.response?.data?.erro || 'Erro. Consulte o suporte técnico.',
          { variant: 'error' }
        );
      }
    } finally {
      setLoading(false);
    }
  }

  const refreshIcon = (row) => (
    <Tooltip title="Retorno" arrow>
      <span>
        <IconButton
          onClick={() => {
            setLinhaSelecionada(row);
            setIsVisualizarObsOpen(true);
          }}
          disabled={!row.obs}
          className={classes.iconbutton_grid}
        >
          <CommentIcon
            fontSize="small"
            className={!row.obs ? 'text-light2' : 'text-success'}
            style={{
              color: 'green',
            }}
          />
        </IconButton>
      </span>
    </Tooltip>
  );

  function OpcoesIcon({ row }) {
    const [openMenu, setOpenMenu] = useState(false);
    const [anchorE1, setAnchorE1] = useState(null);

    const handleClickMenuOpen = (event) => {
      setOpenMenu(true);
      setAnchorE1(event.currentTarget);
    };

    const handleClickMenuClose = () => {
      setOpenMenu(false);
    };

    return (
      <>
        <IconButton
          value={row.id_uuid}
          className={classes.iconbutton_grid}
          onClick={handleClickMenuOpen}
        >
          <ExpandMoreRoundedIcon />
        </IconButton>

        <Menu
          className="user-info"
          id="simple-menu"
          anchorEl={anchorE1}
          keepMounted
          open={openMenu}
          onClose={handleClickMenuClose}
          PaperProps={{
            style: {
              minWidth: 120,
              paddingTop: 0,
              paddingBottom: 0,
            },
          }}
        >
          <MenuItem
            disabled={
              row.status === 'CONFIRMADO' ||
              row.status === 'REJEITADO' ||
              row.status === 'RETORNO' ||
              row.status === 'BAIXADO' ||
              row.status === 'CANCELADO'
            }
            onClick={() => {
              handleCarregarEstruturaEmail(row);
              setLinhaSelecionada(row);
              setEnviarEmail(true);
            }}
          >
            Solicitar Confirmação
          </MenuItem>
          <MenuItem
            disabled={
              row.status === 'CONFIRMADO' ||
              row.status === 'REJEITADO' ||
              row.status === 'RETORNO' ||
              row.status === 'BAIXADO'
            }
            onClick={() => {
              setIsRejeitarOpen(true);
              setLinhaSelecionada(row);
            }}
          >
            Rejeitar
          </MenuItem>
          <Divider />
          <MenuItem
            disabled={row.status === 'REJEITADO' || row.status === 'PENDENTE' || row.status === 'CANCELADO'}
            onClick={() => {
              const hashEmpresa = getHashEmpresa();
              const link = `${linkAlega}/${hashEmpresa}/${row.id_uuid}`;
              copy(link);
              setLinhaSelecionada(row);
            }}
          >
            Copiar Link
          </MenuItem>
          <MenuItem
            disabled={
              row.status === 'PENDENTE' ||
              row.status === 'BAIXADO' ||
              row.status === 'CONFIRMADO' ||
              row.status === 'REJEITADO' ||
              row.status === 'CANCELADO'
            }
            onClick={() => handleReenviarLink(row)}
          >
            Reenviar Link
          </MenuItem>
          <Divider />
          <MenuItem
            disabled={
              row.status === 'PENDENTE' ||
              row.status === 'RETORNO' ||
              row.status === 'BAIXADO' ||
              row.status === 'REJEITADO' ||
              row.status === 'CANCELADO'
            }
            onClick={() => {
              setIsLancarRecebimentoOpen(true);
              setLinhaSelecionada(row);
            }}
          >
            Lançar Recebimento
          </MenuItem>
          <MenuItem
            disabled={row.status !== 'BAIXADO' || row.status === 'CANCELADO'}
            onClick={() => {
              setLinhaSelecionada(row);
              setIsEstornarRecebimentoOpen(true);
            }}
          >
            Estornar Recebimento
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              setIsOcorrenciasOpen(true);
              setLinhaSelecionada(row);
            }}
          >
            Visualizar Ocorrência
          </MenuItem>
          <MenuItem
            disabled={row.status == 'CANCELADO' || linhasSelecionadas.length > 1}
            onClick={() => {
              setCancelarPagamento(true);
              setLinhaSelecionada(row);
            }}
          >
            Cancelar
          </MenuItem>
        </Menu>
      </>
    );
  };

  return (
    <div className="app-wrapper">
      <Toolbar className="table-header" style={{ marginTop: '-20px' }}>
        <div className="title">
          <h2 className="entry-heading">Tá Pago - Portal de Alega Pagamento</h2>
        </div>
        <div className="spacer" />
        <div
          className="actions"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'stretch',
            marginBottom: 5,
          }}
        ></div>
        <div className="actions">
          <Button
            style={{ width: '100px' }}
            aria-controls="customized-menu"
            aria-haspopup="true"
            variant="contained"
            color="primary"
            onClick={abrirMenuMaisAcoes}
            className="jr-btn-sm text-white"
            endIcon={<KeyboardArrowDownIcon />}
          >
            AÇÕES
          </Button>

          <StyledMenu
              keepMounted
              id="fade-menu"
              anchorEl={anchorEl}
              TransitionComponent={Fade}
              open={Boolean(anchorEl)}
              onClose={fecharMenuMaisAcoes}>
              <MenuItem
                disabled={linhasSelecionadas.length <= 1 || !todasLinhasNaoCanceladas}
              >
                <ListItemIcon>
                  <CancelIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary="Cancelamento (Lote)"
                  classes={{ primary: classes.listItemText }}
                  onClick={() => {
                    setCancelarPagamento(true);
                  }}
                  component="button"
                />
              </MenuItem>
          </StyledMenu>
        </div>
      </Toolbar>
      <Paper>
        <div className="row p-1 pt-3 ml-2 mr-2">
          <div className="col-2 p-0 pr-2">
            <TextField
              select
              size="small"
              SelectProps={{
                native: true,
              }}
              variant="outlined"
              label="Localizar por"
              fullWidth
              value={tipoBusca}
              onChange={(e) => setTipoBusca(parseInt(e.target.value))}
            >
              <option value={1}>Processo</option>
              <option value={2}>Devedor</option>
              <option value={3}>CNPJ / CPF</option>
            </TextField>
          </div>
          <div className="col-4 p-0 pr-2">
            <TextField
              fullWidth
              size="small"
              onBlur={(e) => setTxBusca(e.target.value)}
              placeholder="LOCALIZAR..."
              onKeyPress={(ev) => {
                if (ev.key == 'Enter') {
                  setTxBusca(ev.target.value);
                }
              }}
              style={{ fontSize: '12px' }}
              variant="outlined"
            />
          </div>
          <div className="col-2 p-0">
            <TextField
              select
              size="small"
              SelectProps={{
                native: true,
              }}
              variant="outlined"
              fullWidth
              value={tipoData}
              label="por Data"
              onChange={alteraValorSelectData}
            >
              <option value={0}>Nenhum</option>
              <option value={1}>Cadastro</option>
            </TextField>
          </div>
          <div className="col-2 p-0 pl-2">
            <TextField
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              label="Data Inicial"
              type="date"
              variant="outlined"
              fullWidth
              value={dataIni}
              onChange={(e) => setDataIni(e.target.value)}
              disabled={inativarData}
              style={{ backgroundColor: corDisabled }}
            />
          </div>
          <div className="col-2 p-0 pl-2">
            <TextField
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              label="Data Final"
              type="date"
              variant="outlined"
              fullWidth
              value={dataFin}
              onChange={(e) => setDataFin(e.target.value)}
              disabled={inativarData}
              style={{ backgroundColor: corDisabled }}
            />
          </div>
        </div>
        <div className="row p-1 ml-2 mr-2 pb-2">
          <div className="col-2 p-0 pr-2">
            <TextField
              select
              size="small"
              SelectProps={{
                native: true,
              }}
              variant="outlined"
              label="Localizar por"
              fullWidth
              value={tipoCredorOrGrupo}
              onChange={(e) => seTipoCredorOrGrupo(e.target.value)}
            >
              <option value={1}>Credor</option>
              <option value={2}>Grupo</option>
            </TextField>
          </div>
          <div className="col-4 p-0 pr-2" style={{ zIndex: 1000 }}>
            {tipoCredorOrGrupo == 1 && (
              <Select
                options={listaCredores}
                value={credorSelecionado}
                onChange={(event) => {
                  setCredorSelecionado(event);
                  seTipoCredorOrGrupo(1);
                }}
                isSearchable
                isClearable
                placeholder="Selecione a Carteira / Credor"
                getOptionLabel={(option) =>
                  `${option.codigo_formatado} - ${option.razaosocial}`
                }
                getOptionValue={(value) => value.id_uuid}
                noOptionsMessage={() => 'Sem mais opções para selecionar'}
                theme={themeReactSelect}
                fullWidth
              />
            )}
            {tipoCredorOrGrupo == 2 && (
              <Select
                options={listaGrupos}
                value={grupoSelecionado}
                onChange={(event) => {
                  setGrupoSelecionado(event);
                  seTipoCredorOrGrupo(2);
                }}
                isSearchable
                isClearable
                placeholder="Selecione o grupo"
                getOptionLabel={(option) => `${option.nome}`}
                getOptionValue={(value) => value.nome}
                noOptionsMessage={() => 'Sem mais opções para selecionar'}
                theme={themeReactSelect}
              />
            )}
          </div>
          <div className="col-2 p-0">
            <TextField
              select
              size="small"
              SelectProps={{
                native: true,
              }}
              variant="outlined"
              label="Status"
              value={status}
              fullWidth
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="TODOS">TODOS</option>
              <option value="PENDENTE">PENDENTE</option>
              <option value="RETORNO">AGUARDANDO RETORNO</option>
              <option value="CONFIRMADO">CONFIRMADO</option>
              <option value="REJEITADO">REJEITADO</option>
              <option value="BAIXADO">BAIXADO</option>
              <option value="CANCELADO">CANCELADO</option>
            </TextField>
          </div>
          <div className="col-2 p-0 pl-2" style={{ marginLeft: 'auto' }}>
            <Button
              variant="outlined"
              color="primary"
              className="jr-btn-sm"
              onClick={() => listarAlegaPagamentos()}
              fullWidth
            >
              <AutorenewIcon fontSize="small" color="primary" />
              <span>Pesquisar</span>
            </Button>
          </div>
        </div>
        <div className="table-responsive-material">
          <CustomDataGridPro
            name="ListaTaPago"
            enabledSaveState={true}
            columns={columnData}
            data={listaAlega}
            loading={false}
            getRowId={(e) => `${e.id}_${e.status}`}
            onSortModelChange={handleSortDataGridModelChange}
            sortColumnDefault="nome"
            qtdeReg={qtdeReg}
            currentPage={paginationModel.page}
            callbackSetPage={(newPage) =>
              setPaginationModel((prevPaginationModel) => ({
                ...prevPaginationModel,
                page: newPage,
              }))
            }
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setLinhasSelecionadas(newRowSelectionModel);
            }}
            getCountFull={true}
            setPaginationModel={setPaginationModel}
            paginationModel={paginationModel}
            scrollBarTop={scrollBarTop}
            checkboxSelection={true}
          />
        </div>
      </Paper>

      <VisualizarObservacao
        abrirDialog={isVisualizarObsOpen}
        callbackParentFechar={() => setIsVisualizarObsOpen(false)}
        rowSelecionada={linhaSelecionada}
      />
      <VisualizarOcorrencia
        abrirDialog={isOcorrenciasOpen}
        callbackParentFechar={() => setIsOcorrenciasOpen(false)}
        rowSelecionada={linhaSelecionada}
      />

      <RejeitarAlega
        abrirDialog={isRejeitarOpen}
        callbackParentFechar={() => setIsRejeitarOpen(false)}
        rowSelecionada={linhaSelecionada}
        callbackListarAlega={() => listarAlegaPagamentos()}
      />

      <Loading carregar={loading} />

      <LancarRecebimento
        onClose={() => setIsLancarRecebimentoOpen(false)}
        open={isLancarRecebimentoOpen}
        rowSelecionada={linhaSelecionada}
        listarAlegaPagamentos={() => listarAlegaPagamentos()}
      />

      <EstornarRecebimento
        isEstornarRecebimentoOpen={isEstornarRecebimentoOpen}
        handleFecharEstornar={() => setIsEstornarRecebimentoOpen()}
        confirmaEstornarRecebimentoLiberado={
          confirmaEstornarRecebimentoLiberado
        }
      />
      <CancelarPagamento
        abrirCancelar={cancelarPagamento}
        rowsSelecionadas={linhasSelecionadasFormatadas}
        callbackParentFechar={() => setCancelarPagamento(false)}
        hashEmpresa={getHashEmpresa()}
        callbackParentConfirmarCancelar={() =>
          handleClickConfirmaCancelarPagamento()
        }
      />
    </div>
  );
}
