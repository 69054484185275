import React from 'react';
import { List } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

import 'font-awesome/css/font-awesome.min.css';

const NavMenuItem = (props) => {
  const { name, icon, link, bloq, ativo, iconSize } = props;

  return (
    <List component="div" className="nav-menu-item">
      {ativo ? (
        bloq ? (
          <div
            className="prepend-icon nav-menu-link"
            to={link}
            style={{ cursor: 'not-allowed' }}
          >
            {/* Display an icon if any */}
            {!!icon && <i className={icon} style={{ fontSize: iconSize }} />}
            {/* <span className="nav-text">{<IntlMessages id={name}/>}</span> */}
            <span className="nav-text">{name}</span>
          </div>
        ) : (
          <NavLink className="prepend-icon nav-menu-link" to={link}>
            {/* Display an icon if any */}
            {!!icon && <i className={icon} style={{ fontSize: iconSize }} />}
            {/* <span className="nav-text">{<IntlMessages id={name}/>}</span> */}
            <span className="nav-text">{name}</span>
          </NavLink>
        )
      ) : null}
    </List>
  );
};

export default NavMenuItem;
