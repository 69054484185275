import React from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const SnackbarNotification = (props) => {
  return (
    <Snackbar
      open={props.openAlert}
      autoHideDuration={3000}
      disableWindowBlurListener
      onClose={(reason) =>
        reason === 'clickaway' ? 1 : props.callbackParent()
      }
    >
      <SnackbarContent
        className={props.alertInfoTipo}
        message={props.alertInfo}
        style={{ whiteSpace: 'pre-wrap' }} // para quebrar linhas
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => props.callbackParent()}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

export default SnackbarNotification;
