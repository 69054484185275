import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
// import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 3500,
    color: '#fff',
  },
}));

const Loading = (props) => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={props.carregar}>
      {/* <CircularProgress color="inherit" /> */}
      {/* <img src={require('assets/images/carregando.svg')} /> */}
      <i className="zmdi zmdi-spinner zmdi-hc-4x zmdi-hc-spin" />
    </Backdrop>
  );
};

export default Loading;
