import React, { useEffect, useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  TextField,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import api from 'services/api';
import SnackbarNotification from 'components/SnackbarNotification';

export default function VisualizarOcorrencia({
  abrirDialog,
  callbackParentFechar,
  rowSelecionada,
}) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [anotacaoInterna, setAnotacaoInterna] = useState('');
  const [anotacaoExterna, setAnotacaoExterna] = useState('');
  const [alertInfoTipo, setAlertInfoTipo] = useState();
  const [openAlert, setOpenAlert] = useState(false);
  const [alertInfo, setAlertInfo] = useState();

  useEffect(() => {
    if (abrirDialog && rowSelecionada) {
      if (rowSelecionada.anotaint) {
        setAnotacaoInterna(rowSelecionada.anotaint);
      }

      if (rowSelecionada.anotaext) {
        setAnotacaoExterna(rowSelecionada.anotaext);
      }
    }
  }, [abrirDialog, rowSelecionada]);

  const handleAlterarAnotacao = async () => {
    try {
      const data = {
        uuidOcorrencia: rowSelecionada.uuid_ocorrencia,
        anotaInt: anotacaoInterna,
        anotaExt: anotacaoExterna,
      };
      const response = await api.post('/editar_ocorrencias', { data });
      if (response.status === 200) {
        setAlertInfo(`Atualizado com sucesso!`);
        setAlertInfoTipo('mb-3 bg-success');
        setOpenAlert(true);
      }
    } catch (error) {
      setAlertInfo(`Erro ao alterar as ocorrências`);
      setAlertInfoTipo('mb-3 bg-danger');
      setOpenAlert(true);
    }
  };
  return (
    <>
      {abrirDialog ? (
        <Dialog
          open={abrirDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">
            Ocorrências - {rowSelecionada.data_cad_br} /{' '}
            {rowSelecionada.descstatus}
          </DialogTitle>
          <DialogContent>
            <div className="row">
              <div className="col-6">
                <TextField
                  autoFocus
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 30000,
                    readOnly: !isEditMode,
                  }}
                  margin="dense"
                  id="tx_oco_int"
                  name="tx_oco_int"
                  label="Ocorrência Interna"
                  type="text"
                  minRows={10}
                  maxRows={10}
                  multiline
                  fullWidth
                  value={anotacaoInterna}
                  onChange={(e) => setAnotacaoInterna(e.target.value)}
                />
              </div>
              <div className="col-6">
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 30000,
                    readOnly: !isEditMode,
                  }}
                  margin="dense"
                  id="tx_oco_ext"
                  name="tx_oco_ext"
                  label="Ocorrência Externa"
                  type="text"
                  minRows={10}
                  maxRows={10}
                  multiline
                  fullWidth
                  value={anotacaoExterna}
                  onChange={(e) => setAnotacaoExterna(e.target.value)}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            {!isEditMode && (
              <Button color="secondary" onClick={() => setIsEditMode(true)}>
                <EditIcon fontSize="small" className="mr-2" />
                Editar
              </Button>
            )}
            {isEditMode && (
              <div>
                <Button
                  className="text-success"
                  onClick={() => {
                    handleAlterarAnotacao();
                    setIsEditMode(false);
                  }}
                >
                  Salvar
                </Button>
                <Button
                  className="text-danger"
                  onClick={() => setIsEditMode(false)}
                >
                  Cancelar
                </Button>
              </div>
            )}
            <Button
              onClick={() => {
                callbackParentFechar();
                setIsEditMode(false);
              }}
              autoFocus
            >
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      <SnackbarNotification
        openAlert={openAlert}
        alertInfoTipo={alertInfoTipo}
        alertInfo={alertInfo}
        callbackParent={() => setOpenAlert(false)}
      />
    </>
  );
}
