import axios from 'axios';

import { getToken } from 'util/cobUtils';

const api = axios.create({
  // baseURL: 'http://localhost:3333',
  // baseURL: 'http://192.168.15.142:3333'
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
