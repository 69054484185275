import {
  DADOS_PROCESSO,
  DADOS_DOCUMENTO,
  DADOS_DISCADOR,
  CONNECTION_WS,
} from 'constants/ActionTypes';

export function setReduxDadosProcesso(dadosProcesso) {
  return { type: DADOS_PROCESSO, dadosProcesso };
}

export function setReduxDadosDocumento(dadosDocumento) {
  return { type: DADOS_DOCUMENTO, dadosDocumento };
}

export function setReduxDadosDiscador(dadosDiscador) {
  return { type: DADOS_DISCADOR, dadosDiscador };
}

export function setReduxConnectionWs(connectionWs) {
  return { type: CONNECTION_WS, connectionWs };
}
