import jwt from 'jsonwebtoken';

import { format } from 'date-fns';

const crypto = require('crypto');

const algorithm = 'aes-256-cbc';
const key = process.env.REACT_APP_KEY;

export const encrypt = (text) => {
  const cipher = crypto.createCipher(algorithm, key);
  let crypted = cipher.update(text, 'utf8', 'hex');
  crypted += cipher.final('hex');

  return crypted;
};

export const decrypt = (text) => {
  const decipher = crypto.createDecipher(algorithm, key);
  let dec = decipher.update(text, 'hex', 'utf8');
  dec += decipher.final('utf8');
  return dec;
};

// codigo empresa
export const getHashEmpresa = () => localStorage.getItem('cobcloud_token2');

// token jwt
export const getToken = () => localStorage.getItem('cobcloud_token');

// uuid do usuario
export const getIdUser = () => {
  const token = getToken();

  if (token !== null) {
    try {
      const tokenDecoded = jwt.verify(token, key);
      const subtokenCobcloud = decrypt(tokenDecoded.sub);
      if (subtokenCobcloud === 'crmcobcloud') {
        return tokenDecoded.uid;
      }

      return 0;
    } catch {
      return 0;
    }
  } else {
    return 0;
  }
};

export const getToken2FA = (authUser2FA) => {
  const token = authUser2FA?.token;

  if (token !== null) {
    try {
      const tokenDecoded = jwt.verify(token, key);
      const subtokenCobcloud = decrypt(tokenDecoded.sub);
      if (subtokenCobcloud === 'crmcobcloud') {
        if (tokenDecoded.data.TwoFa) return tokenDecoded.data.TwoFa;
        return 0;
      }
      return 0;
    } catch {
      return 0;
    }
  } else {
    return 0;
  }
};

// verifica se está autenticado
export const isAuthenticated = () => {
  const token = getToken();

  if (token !== null) {
    try {
      const tokenDecoded = jwt.verify(token, key);
      const subtokenCobcloud = decrypt(tokenDecoded.sub);
      if (subtokenCobcloud === 'crmcobcloud') {
        return true;
      }

      return false;
    } catch {
      // console.log('Token Error');
      return false;
    }
  } else {
    // console.log('Token Null');
    return false;
  }
};

export function formatMoney(
  amount,
  decimalCount = 2,
  decimal = ',',
  thousands = '.'
) {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = Number.isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    const i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    // console.log(e);
  }
}

const countDecimals = (value) => {
  if (Math.floor(value) !== value)
    return value.toString().split('.')[1].length || 0;
  return 0;
};

export function formatPercent(
  amount,
  decimalCount = 3,
  decimal = ',',
  thousands = '.'
) {
  try {
    decimalCount = countDecimals(amount);

    if (decimalCount < 3) decimalCount = 3;
    else if (decimalCount > 5) decimalCount = 5;

    decimalCount = Math.abs(decimalCount);
    decimalCount = Number.isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    const i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    // console.log(e);
  }
}

export function getDataHojeFile() {
  const dataHoje = format(new Date(), 'ddMMyyyy_HHmmss');
  return dataHoje;
}

export function formatarCpfCnpj(data) {
  if (data && data.length <= 11) {
    const dados = data.padStart(11, 0);
    const cpfFormatado = dados.replace(
      /(\d{3})(\d{3})(\d{3})(\d{2})/g,
      '$1.$2.$3-$4'
    );
    return cpfFormatado;
  }

  const dados = data.padStart(14, 0);
  const cnpjFormatado = dados.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    '$1.$2.$3/$4-$5'
  );
  return cnpjFormatado;
}

export function validarCPF(cpf) {
  // Remover caracteres não numéricos
  cpf = cpf.replace(/[^\d]/g, '');

  // Verificar se o CPF possui 11 dígitos
  if (cpf.length !== 11) {
    return false;
  }

  // Calcular o primeiro dígito verificador
  let soma = 0;
  for (let i = 0; i < 9; i++) {
    soma += parseInt(cpf.charAt(i)) * (10 - i);
  }
  let resto = 11 - (soma % 11);
  const digitoVerificador1 = resto === 10 || resto === 11 ? 0 : resto;

  // Calcular o segundo dígito verificador
  soma = 0;
  for (let i = 0; i < 10; i++) {
    soma += parseInt(cpf.charAt(i)) * (11 - i);
  }
  resto = 11 - (soma % 11);
  const digitoVerificador2 = resto === 10 || resto === 11 ? 0 : resto;

  // Verificar se os dígitos verificadores estão corretos
  if (
    parseInt(cpf.charAt(9)) !== digitoVerificador1 ||
    parseInt(cpf.charAt(10)) !== digitoVerificador2
  ) {
    return false;
  }

  return true;
}

export function validarCNPJ(cnpj) {
  // Remover caracteres não numéricos
  cnpj = cnpj.replace(/[^\d]/g, '');

  // Verificar se o CNPJ possui 14 dígitos
  if (cnpj.length !== 14) {
    return false;
  }

  // Calcular o primeiro dígito verificador
  let soma = 0;
  let peso = 5;
  for (let i = 0; i < 12; i++) {
    soma += parseInt(cnpj.charAt(i)) * peso;
    peso = peso === 2 ? 9 : peso - 1;
  }
  let resto = soma % 11;
  const digitoVerificador1 = resto < 2 ? 0 : 11 - resto;

  // Calcular o segundo dígito verificador
  soma = 0;
  peso = 6;
  for (let i = 0; i < 13; i++) {
    soma += parseInt(cnpj.charAt(i)) * peso;
    peso = peso === 2 ? 9 : peso - 1;
  }
  resto = soma % 11;
  const digitoVerificador2 = resto < 2 ? 0 : 11 - resto;

  // Verificar se os dígitos verificadores estão corretos
  if (
    parseInt(cnpj.charAt(12)) !== digitoVerificador1 ||
    parseInt(cnpj.charAt(13)) !== digitoVerificador2
  ) {
    return false;
  }

  return true;
}

export function getISBP(cod_banco) {
  const lista = [
    {
      'Nome Completo': 'Banco A.J. RENNER',
      Código: '654',
      ISPB: 92874270,
    },
    {
      'Nome Completo': 'Banco ABC Brasil',
      Código: '246',
      ISPB: 28195667,
    },
    {
      'Nome Completo': 'Banco ABN AMRO',
      Código: '075',
      ISPB: 3532415,
    },
    {
      'Nome Completo': 'Banco ALFA',
      Código: '025',
      ISPB: 3323840,
    },
    {
      'Nome Completo': 'Banco ARBI',
      Código: '213',
      ISPB: 54403563,
    },
    {
      'Nome Completo': 'Banco AZTECA do Brasil',
      Código: '019',
      ISPB: 9391857,
    },
    {
      'Nome Completo': 'Banco BARCLAYS',
      Código: '740',
      ISPB: 61146577,
    },
    {
      'Nome Completo': 'Banco BBM',
      Código: '107',
      ISPB: 15114366,
    },
    {
      'Nome Completo': 'Banco BMFBOVESPA',
      Código: '096',
      ISPB: 997185,
    },
    {
      'Nome Completo': 'Banco BMG',
      Código: '318',
      ISPB: 61186680,
    },
    {
      'Nome Completo': 'Banco BNP PARIBAS Brasil',
      Código: '752',
      ISPB: 1522368,
    },
    {
      'Nome Completo': 'Banco BOA VISTA INTERATLANTICO',
      Código: '248',
      ISPB: 33485541,
    },
    {
      'Nome Completo': 'Banco BONSUCESSO',
      Código: '218',
      ISPB: 71027866,
    },
    {
      'Nome Completo': 'Banco BRACCE',
      Código: '065',
      ISPB: 48795256,
    },
    {
      'Nome Completo': 'Banco BRADESCARD',
      Código: '063',
      ISPB: 4184779,
    },
    {
      'Nome Completo': 'Banco BRADESCO',
      Código: '237',
      ISPB: 60746948,
    },
    {
      'Nome Completo': 'Banco BRADESCO BBI',
      Código: '036',
      ISPB: 60746948,
    },
    {
      'Nome Completo': 'Banco BRADESCO CARTÕES',
      Código: '204',
      ISPB: 59438325,
    },
    {
      'Nome Completo': 'Banco BRADESCO FINANCIAMENTOS',
      Código: '394',
      ISPB: 7207996,
    },
    {
      'Nome Completo': 'Banco BTG PACTUAL',
      Código: '208',
      ISPB: 30306294,
    },
    {
      'Nome Completo': 'Banco CACIQUE',
      Código: '263',
      ISPB: 33349358,
    },
    {
      'Nome Completo': 'Banco CAIXA GERAL – Brasil',
      Código: '473',
      ISPB: 33466988,
    },
    {
      'Nome Completo': 'Banco CAPITAL',
      Código: '412',
      ISPB: 15173776,
    },
    {
      'Nome Completo': 'Banco CARGILL',
      Código: '040',
      ISPB: 3609817,
    },
    {
      'Nome Completo': 'Banco CEDULA',
      Código: '266',
      ISPB: 33132044,
    },
    {
      'Nome Completo': 'Banco CETELEM',
      Código: '739',
      ISPB: 558456,
    },
    {
      'Nome Completo': 'Banco CIFRA',
      Código: '233',
      ISPB: 62421979,
    },
    {
      'Nome Completo': 'Banco CITIBANK',
      Código: '745',
      ISPB: 33479023,
    },
    {
      'Nome Completo': 'Banco CLÁSSICO',
      Código: '241',
      ISPB: 31597552,
    },
    {
      'Nome Completo': 'Banco COOPERATIVO do Brasil – Bancoob',
      Código: '756',
      ISPB: 2038232,
    },
    {
      'Nome Completo': 'Banco COOPERATIVO SICREDI',
      Código: '748',
      ISPB: 1181521,
    },
    {
      'Nome Completo': 'Banco CREDIT AGRICOLE Brasil',
      Código: '222',
      ISPB: 75647891,
    },
    {
      'Nome Completo': 'Banco CREDIT SUISSE (Brasil)',
      Código: '505',
      ISPB: 32062580,
    },
    {
      'Nome Completo': 'Banco da AMAZONIA',
      Código: '003',
      ISPB: 4902979,
    },
    {
      'Nome Completo': 'Banco da CHINA Brasil',
      Código: '083',
      ISPB: 10690848,
    },
    {
      'Nome Completo': 'Banco DAYCOVAL',
      Código: '707',
      ISPB: 62232889,
    },
    {
      'Nome Completo': 'Banco de LA NACION ARGENTINA',
      Código: '300',
      ISPB: 33042151,
    },
    {
      'Nome Completo': 'Banco de LA PROVINCIA de BUENOS AIRES',
      Código: '495',
      ISPB: 44189447,
    },
    {
      'Nome Completo': 'Banco de LA REPUBLICA ORIENTAL DEL URUGUAY',
      Código: '494',
      ISPB: 51938876,
    },
    {
      'Nome Completo': 'Banco de TOKYO MITSUBISHI UFJ Brasil',
      Código: '456',
      ISPB: 60498557,
    },
    {
      'Nome Completo': 'Banco DIBENS',
      Código: '214',
      ISPB: 61199881,
    },
    {
      'Nome Completo': 'Banco do BRASIL',
      Código: '001',
      ISPB: 0,
    },
    {
      'Nome Completo': 'Banco do ESTADO de SERGIPE',
      Código: '047',
      ISPB: 13009717,
    },
    {
      'Nome Completo': 'Banco do ESTADO do PARA',
      Código: '037',
      ISPB: 4913711,
    },
    {
      'Nome Completo': 'Banco do ESTADO do RIO GRANDE do SUL (BANRISUL)',
      Código: '041',
      ISPB: 92702067,
    },
    {
      'Nome Completo': 'Banco do NORDESTE do Brasil',
      Código: '004',
      ISPB: 7237373,
    },
    {
      'Nome Completo': 'Banco FATOR',
      Código: '265',
      ISPB: 33644196,
    },
    {
      'Nome Completo': 'Banco FIBRA',
      Código: '224',
      ISPB: 58616418,
    },
    {
      'Nome Completo': 'Banco FICSA',
      Código: '626',
      ISPB: 61348538,
    },
    {
      'Nome Completo': 'Banco GERADOR',
      Código: '121',
      ISPB: 10664513,
    },
    {
      'Nome Completo': 'Banco GUANABARA',
      Código: '612',
      ISPB: 31880826,
    },
    {
      'Nome Completo': 'Banco INDUSTRIAL do Brasil',
      Código: '604',
      ISPB: 31895683,
    },
    {
      'Nome Completo': 'Banco INDUSTRIAL e COMERCIAL',
      Código: '320',
      ISPB: 7450604,
    },
    {
      'Nome Completo': 'Banco INDUSVAL',
      Código: '653',
      ISPB: 61024352,
    },
    {
      'Nome Completo': 'Banco INTERCAP',
      Código: '630',
      ISPB: 58497702,
    },
    {
      'Nome Completo': 'Banco INTERMEDIUM',
      Código: '077',
      ISPB: 416968,
    },
    {
      'Nome Completo': 'Banco INVESTCRED UNIBANCO',
      Código: '249',
      ISPB: 61182408,
    },
    {
      'Nome Completo': 'Banco ITAU BBA',
      Código: '184',
      ISPB: 17298092,
    },
    {
      'Nome Completo': 'Banco ITAÚ HOLDING FINANCEIRA',
      Código: '652',
      ISPB: 60872504,
    },
    {
      'Nome Completo': 'Banco J. SAFRA',
      Código: '074',
      ISPB: 3017677,
    },
    {
      'Nome Completo': 'Banco J.P. MORGAN',
      Código: '376',
      ISPB: 33172537,
    },
    {
      'Nome Completo': 'Banco JOHN DEERE',
      Código: '217',
      ISPB: 91884981,
    },
    {
      'Nome Completo': 'Banco KDB do Brasil',
      Código: '076',
      ISPB: 7656500,
    },
    {
      'Nome Completo': 'Banco KEB do Brasil',
      Código: '757',
      ISPB: 2318507,
    },
    {
      'Nome Completo': 'Banco LUSO BRASILEIRO',
      Código: '600',
      ISPB: 59118133,
    },
    {
      'Nome Completo': 'Banco MÁXIMA',
      Código: '243',
      ISPB: 33923798,
    },
    {
      'Nome Completo': 'Banco MERCANTIL do BRASIL',
      Código: '389',
      ISPB: 17184037,
    },
    {
      'Nome Completo': 'Banco MIZUHO do Brasil',
      Código: '370',
      ISPB: 61088183,
    },
    {
      'Nome Completo': 'Banco MODAL',
      Código: '746',
      ISPB: 30723886,
    },
    {
      'Nome Completo': 'Banco MORGAN STANLEY DEAN WITTER',
      Código: '066',
      ISPB: 2801938,
    },
    {
      'Nome Completo': 'Banco ORIGINAL',
      Código: '212',
      ISPB: 92894922,
    },
    {
      'Nome Completo': 'Banco ORIGINAL do Agronegócio',
      Código: '079',
      ISPB: 9516419,
    },
    {
      'Nome Completo': 'Banco PANAMERICANO',
      Código: '623',
      ISPB: 59285411,
    },
    {
      'Nome Completo': 'Banco PAULISTA',
      Código: '611',
      ISPB: 61820817,
    },
    {
      'Nome Completo': 'Banco PECUNIA',
      Código: '613',
      ISPB: 60850229,
    },
    {
      'Nome Completo': 'Banco PETRA',
      Código: '094',
      ISPB: 11758741,
    },
    {
      'Nome Completo': 'Banco PINE',
      Código: '643',
      ISPB: 62144175,
    },
    {
      'Nome Completo': 'Banco POTTENCIAL',
      Código: '735',
      ISPB: 253448,
    },
    {
      'Nome Completo': 'Banco RABOBANK INTERNATIONAL Brasil',
      Código: '747',
      ISPB: 1023570,
    },
    {
      'Nome Completo': 'Banco RANDON',
      Código: '088',
      ISPB: 11476673,
    },
    {
      'Nome Completo': 'Banco RENDIMENTO',
      Código: '633',
      ISPB: 68900810,
    },
    {
      'Nome Completo': 'Banco RIBEIRAO PRETO',
      Código: '741',
      ISPB: 517645,
    },
    {
      'Nome Completo': 'Banco RODOBENS',
      Código: '120',
      ISPB: 33603457,
    },
    {
      'Nome Completo': 'Banco SAFRA',
      Código: '422',
      ISPB: 58160789,
    },
    {
      'Nome Completo': 'Banco SANTANDER (Brasil)',
      Código: '033',
      ISPB: 90400888,
    },
    {
      'Nome Completo': 'Banco SEMEAR',
      Código: '743',
      ISPB: 795423,
    },
    {
      'Nome Completo': 'Banco SOCIETE GENERALE Brasil',
      Código: '366',
      ISPB: 61533584,
    },
    {
      'Nome Completo': 'Banco SOFISA',
      Código: '637',
      ISPB: 60889128,
    },
    {
      'Nome Completo': 'Banco SUMITOMO MITSUI Brasileiro',
      Código: '464',
      ISPB: 60518222,
    },
    {
      'Nome Completo': 'Banco TOPAZIO',
      Código: '082',
      ISPB: 7679404,
    },
    {
      'Nome Completo': 'Banco TRIÂNGULO',
      Código: '634',
      ISPB: 17351180,
    },
    {
      'Nome Completo': 'Banco VOTORANTIM',
      Código: '655',
      ISPB: 59588111,
    },
    {
      'Nome Completo': 'Banco VR',
      Código: '610',
      ISPB: 78626983,
    },
    {
      'Nome Completo': 'Banco WESTERN UNION do Brasil',
      Código: '119',
      ISPB: 13720915,
    },
    {
      'Nome Completo': 'Banco WOORI BANK do Brasil',
      Código: '124',
      ISPB: 15357060,
    },
    {
      'Nome Completo': 'BANESTES (Banco do ESTADO do ESPIRITO SANTO)',
      Código: '021',
      ISPB: 28127603,
    },
    {
      'Nome Completo': 'BANIF – Banco INTERNACIONAL do FUNCHAL (Brasil)',
      Código: '719',
      ISPB: 33884941,
    },
    {
      'Nome Completo': 'BANK OF AMERICA MERRILL LYNCH Banco Múltiplo',
      Código: '755',
      ISPB: 62073200,
    },
    {
      'Nome Completo': 'BCV – Banco de Crédito e Varejo',
      Código: '250',
      ISPB: 50585090,
    },
    {
      'Nome Completo': 'BES Investimento do Brasil – Banco de Investimento',
      Código: '078',
      ISPB: 34111187,
    },
    {
      'Nome Completo': 'BM TRICURY',
      Código: '018',
      ISPB: 57839805,
    },
    {
      'Nome Completo': 'BNY MELLON',
      Código: '017',
      ISPB: 42272526,
    },
    {
      'Nome Completo': 'BPN Brasil Banco Múltiplo',
      Código: '069',
      ISPB: 61033106,
    },
    {
      'Nome Completo': 'BRADESCO BERJ',
      Código: '122',
      ISPB: 33147315,
    },
    {
      'Nome Completo': 'BRASIL PLURAL Banco Múltiplo',
      Código: '125',
      ISPB: 45246410,
    },
    {
      'Nome Completo': 'BRB – Banco de Brasília',
      Código: '070',
      ISPB: 208,
    },
    {
      'Nome Completo': 'BRICKELL Crédito, Financiamento e Investimento',
      Código: '092',
      ISPB: 12865507,
    },
    {
      'Nome Completo': 'CAIXA ECONOMICA FEDERAL',
      Código: '104',
      ISPB: 360305,
    },
    {
      'Nome Completo': 'CC CREDICOAMO Crédito Rural Cooperativa',
      Código: '010',
      ISPB: 81723108,
    },
    {
      'Nome Completo': 'CC UNICRED Brasil Central',
      Código: '112',
      ISPB: 4243780,
    },
    {
      'Nome Completo': 'CC UNICRED do Brasil',
      Código: '136',
      ISPB: 315557,
    },
    {
      'Nome Completo': 'CC UNIPRIME NORTE do PARANA',
      Código: '084',
      ISPB: 2398976,
    },
    {
      'Nome Completo':
        'CECOOPES – Central das Cooperativas de Economia e Crédito Mútuo',
      Código: '114',
      ISPB: 5790149,
    },
    {
      'Nome Completo': 'CITIBANK N.A.',
      Código: '477',
      ISPB: 33042953,
    },
    {
      'Nome Completo': 'Cooperativa Central de Crédito do Estado de SP',
      Código: '090',
      ISPB: 73085573,
    },
    {
      'Nome Completo': 'Cooperativa Central de Crédito NOROESTE Brasileiro',
      Código: '097',
      ISPB: 4632856,
    },
    {
      'Nome Completo': 'Cooperativa Central de Crédito Urbano – CECRED',
      Código: '085',
      ISPB: 5463212,
    },
    {
      'Nome Completo': 'Cooperativa de Crédito Rural da Região da Mogiana',
      Código: '089',
      ISPB: 62109566,
    },
    {
      'Nome Completo': 'CREDIALIANCA Cooperativa de Crédito RURAL',
      Código: '098',
      ISPB: 78157146,
    },
    {
      'Nome Completo': 'DEUTSCHE BANK – Banco ALEMÃO',
      Código: '487',
      ISPB: 62331228,
    },
    {
      'Nome Completo': 'HIPERCARD Banco Múltiplo',
      Código: '062',
      ISPB: 3012230,
    },
    {
      'Nome Completo': 'HSBC BANK Brasil – Banco Múltiplo',
      Código: '399',
      ISPB: 1701201,
    },
    {
      'Nome Completo': 'ICBC do Brasil Banco Múltiplo',
      Código: '132',
      ISPB: 17453575,
    },
    {
      'Nome Completo': 'ING BANK N.V.',
      Código: '492',
      ISPB: 49336860,
    },
    {
      'Nome Completo': 'ITAÚ UNIBANCO',
      Código: '341',
      ISPB: 60701190,
    },
    {
      'Nome Completo': 'NOVO Banco CONTINENTAL',
      Código: '753',
      ISPB: 74828799,
    },
    {
      'Nome Completo': 'PARANA Banco',
      Código: '254',
      ISPB: 14388334,
    },
    {
      'Nome Completo': 'SCOTIABANK Brasil Banco Múltiplo',
      Código: '751',
      ISPB: 29030467,
    },
    {
      'Nome Completo':
        'UNICRED Central RS – Central de Cooperativa de Economia e Crédito Mútuo do Estado do RS',
      Código: '091',
      ISPB: 1634601,
    },
    {
      'Nome Completo': 'UNICRED Central Santa Catarina',
      Código: '087',
      ISPB: 543968,
    },
    {
      'Nome Completo':
        'UNIPRIME Central – Central Interestadual de Cooperativa de Crédito',
      Código: '099',
      ISPB: 3046391,
    },
  ];
}
