import React from 'react';

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  TextField,
} from '@material-ui/core';

export default function VisualizarObservacao({
  abrirDialog,
  callbackParentFechar,
  rowSelecionada,
}) {
  return (
    <>
      {abrirDialog ? (
        <Dialog
          open={abrirDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">Observações Credor</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 30000,
                readOnly: true,
              }}
              margin="dense"
              id="tx_oco_int"
              name="tx_oco_int"
              type="text"
              minRows={8}
              maxRows={8}
              multiline
              fullWidth
              value={rowSelecionada.obs}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                callbackParentFechar();
              }}
              autoFocus
            >
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
}
