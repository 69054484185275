import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';

import { Grid, InputAdornment } from '@material-ui/core';
import { encrypt } from 'util/cobUtils';
import { AccountCircle } from '@material-ui/icons';
import { userSignInSuccess } from 'actions/Auth';
import img from '../assets/images/imagem6.jpg';
import logo from '../assets/images/logo-cobcloud.png';
import logoSSL from '../assets/images/siteseal-positive-ssl.png';
import api from 'services/api';
import { resetFiltersRedux } from 'actions/Filters';

const useStyles = makeStyles({
  inputUpper: {
    textTransform: 'uppercase',
  },
});

const Code2FA = (props) => {
  const classes = useStyles();

  const [code2FA, setCode2FA] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  const [ativaBotaoConfirma, setAtivaBotaoConfirma] = useState(true);

  const authUser2FA = useSelector(({ auth }) => auth.authenticated_2f2);

  const dispatch = useDispatch();

  async function confere2FA() {
    if (code2FA != '') {
      setShowMessage(false);
      setAtivaBotaoConfirma(false);

      try {
        const {
          id_uuid: cobcloud_user,
          token: cobcloud_token,
          codemp: cobcloud_token2,
        } = authUser2FA;

        let access = authUser2FA;
        access = JSON.stringify(access, undefined, '');
        access = encrypt(access);

        const response = await api.post(
          `/google_authenticator_verify/${cobcloud_user}`,
          { data: { code2FA } }
        );

        if (response.status === 200) {
          localStorage.setItem('cobcloud_user', cobcloud_user);
          localStorage.setItem('cobcloud_token', cobcloud_token);
          localStorage.setItem('cobcloud_token2', cobcloud_token2);
          localStorage.setItem('access', access);
          
/*           dispatch(resetFiltersRedux());
          dispatch(userSignInSuccess(authUser2FA)); */  

          window.location.reload();
        }
      } catch {
        setShowMessage(true);
        setAlertMessage('Código inválido, tente novamente!');
        setAtivaBotaoConfirma(true);
      }
    }
  }

  return (
    <div>
      <Grid container style={{ height: '100vh', overflowY: 'auto' }}>
        <Grid item xs={12} sm={8}>
          <img
            src={img}
            alt="bussines"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={4}
          alignItems="center"
          direction="column"
          justifyContent="center"
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0',
              margin: '0',
              minWidth: '80%',
              maxWidth: '90%',
            }}
          >
            <Grid container justifyContent="center">
              <img
                src={logo}
                alt="logo"
                style={{ width: '70%', height: 'auto' }}
              />
            </Grid>
            <div style={{ height: 20 }} />
            <TextField
              disabled={!ativaBotaoConfirma}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
                className: classes.inputUpper,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
              label="Código 2FA"
              onChange={(e) => setCode2FA(e.target.value)}
              value={code2FA}
              margin="normal"
              onKeyPress={(ev) => {
                if (ev.key == 'Enter') {
                  confere2FA();
                }
              }}
            />
            <div style={{ height: 20 }} />
            <Button
              onClick={() => {
                confere2FA();
              }}
              disabled={!ativaBotaoConfirma}
              variant="contained"
              className="jr-btn-sm bg-indigo text-white"
            >
              {ativaBotaoConfirma ? (
                <div>
                  <span>Validar</span>
                </div>
              ) : (
                <div>
                  <i className="zmdi zmdi-refresh zmdi-hc-fw zmdi-hc-spin mr-2" />
                  <span>Aguarde...</span>
                </div>
              )}
            </Button>
            <div style={{ height: 20 }} />
            <Alert hidden={!showMessage} severity="error">
              {alertMessage}
            </Alert>

            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'center',
                position: 'absolute',
                bottom: '10px',
                right: '20px',
              }}
            >
              <img src={logoSSL} alt="logossl" />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Code2FA;
