import React from 'react';

const SearchBox = ({
  styleName,
  placeholder2,
  onChange,
  onKeyPress,
  onClick,
  value,
}) => {
  return (
    <div
      className={`search-bar right-side-icon bg-transparent ${styleName}`}
      style={{ maxWidth: '210px' }}
    >
      <div className="form-group">
        <input
          className="form-control border-0"
          type="search"
          placeholder={placeholder2}
          onChange={onChange}
          value={value}
          onKeyPress={onKeyPress}
        />
        <button className="search-icon" onClick={onClick}>
          <i className="zmdi zmdi-search zmdi-hc-lg" />
        </button>
      </div>
    </div>
  );
};
export default SearchBox;

SearchBox.defaultProps = {
  styleName: '',
  value: '',
};
