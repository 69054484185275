import React, { Component } from 'react';
import Nprogress from 'nprogress';
import ReactPlaceholder from 'react-placeholder';
import 'nprogress/nprogress.css';
import 'react-placeholder/lib/reactPlaceholder.css';
import CircularProgress from '../components/CircularProgress/index';

export default function asyncComponent(importComponent) {
  class AsyncFunc extends Component {
    constructor(props) {
      super(props);
      this.state = {
        component: null,
        retryCount: 0,
      };
      this.maxRetries = 3;
    }

    UNSAFE_componentWillMount() {
      Nprogress.start();
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    async loadComponent() {
      try {
        const { default: Component } = await importComponent();
        if (this.mounted) {
          this.setState({
            component: <Component {...this.props} />,
          });
          Nprogress.done();
        }
      } catch (error) {
        if (/Loading chunk [\d]+ failed/.test(error.message)) {
          if (this.state.retryCount < this.maxRetries) {
            setTimeout(() => {
              this.setState(
                (prevState) => ({
                  retryCount: prevState.retryCount + 1,
                }),
                () => {
                  this.loadComponent(); // Tenta carregar novamente
                }
              );
            }, 1000);
          } else {
            console.error('Falha ao carregar chunk após várias tentativas.');
            window.location.reload(); // Força o reload após 3 falhas
          }
        } else {
          console.error('Erro inesperado ao carregar o componente:', error);
        }
      }
    }

    async componentDidMount() {
      this.mounted = true;
      this.loadComponent();
    }

    render() {
      const Component = this.state.component || (
        <div className="loader-view" style={{ height: 'calc(100vh - 200px)' }}>
          <CircularProgress />
        </div>
      );
      return (
        <ReactPlaceholder type="text" rows={7} ready={Component !== null}>
          {Component}
        </ReactPlaceholder>
      );
    }
  }

  return AsyncFunc;
}
