import Moment from 'moment';
import {
  FILTROS_CREDOR,
  FILTROS_DEVEDOR,
  FILTROS_RECEBIMENTOS_LIBERADOS,
  RESET_REDUX_RECEBIMENTOS_LIBERADOS,
  FILTROS_ACIONAR,
  RESET_FILTER_REDUX,
  FILTROS_ACORDOS_PROGRAMADOS,
  RESET_FILTROS_ACORDOS_PROGRAMADOS,
  FILTROS_MINHA_AGENDA,
  RESET_FILTROS_MINHA_AGENDA,
} from 'constants/ActionTypes';

const INIT_STATE = {
  lista_credor: {
    tipoBusca: 0,
    textoBusca: '',
    order: 'asc',
    orderBy: 'c.razaosocial',
    page: 0,
    limit: 10,
    inativos: false,
    grupo: null,
  },
  lista_devedores: {
    tipoBuscaCredor: 0,
    credorSelecionado: null,
    grupoSelecionado: null,
    tipoBuscaStatus: 1,
    tipoBusca: 'processo',
    textoBusca: '',
    order: 'asc',
    orderBy: 'd.razaosocial',
    page: 0,
    limit: 10,
    colunasAtivas: {
      processo: true,
      credor: true,
      cod_ext: false,
      razaosocial: true,
      cnpj: true,
      end_cidade: true,
      end_uf: true,
      vl_total: true,
      status: true,
      ocorrencia_ultima: false,
      cobrador: false,
      campo1: false,
      campo2: false,
      campo3: false,
      campo4: false,
      campo5: false,
      campo6: false,
      campo7: false,
      campo8: false,
      campo9: false,
      campo10: false,
      actions: true,
    },
  },
  lista_recebimentos: {
    page: 0,
    rowsPerPage: 10,
    tipoBuscaData: 1,
    tipoBusca: 0,
    txBusca: '',
    tipoBuscaStatus: 1,
    dataIni: Moment(new Date()).format('YYYY-MM-DD'),
    dataFim: Moment(new Date()).format('YYYY-MM-DD'),
    credorSelecionado: null,
    grupoSelecionado: null,
    contaSelecionada: null,
    tipoBuscaCredor: '0',
    tipoBuscaFormaPagto: 'TODOS',
    tipoBuscaLocalPagto: 0,
    tipoBuscaOutrosFiltros: 0,
    orderBy: 'data_cad',
    order: 'asc',
  },
  lista_acionar: {
    coresSelecionadas: [],
    negociadorSelecionado: null,
    statusProcessoSelecionados: [],
    textoBusca: '',
    tipoBusca: 0,
    order: '',
    orderBy: '',
    limit: 10,
    page: 0,
    colunasAtivas: null,
  },
  lista_acordos_programados: {
    tipoBusca: 0,
    txBusca: '',
    tipoBuscaData: 1,
    dataIni: Moment(new Date()).format('YYYY-MM-DD'),
    dataFim: Moment(new Date()).format('YYYY-MM-DD'),
    tipoBuscaNegociador: 0,
    usuarioLogin: null,
    tipoBuscaFormaPagto: '0',
    statusProcesso: [],
    filtroStatusAberto: true,
    filtroStatusQuitado: false,
    filtroStatusCancelado: false,
    filtroStatusConfirmado: true,
  },
  lista_minha_agenda: {
    tipoBusca: 0,
    tipoBuscaData: 1,
    tipoAgenda: 0,
    negociadorSelecionado: null,
    txBusca: '',
    dataIni: Moment(new Date()).format('YYYY-MM-DD'),
    dataFim: Moment(new Date()).format('YYYY-MM-DD'),
    order: '',
    orderBy: '',
    page: 0,
    limit: 10,
  },
};

const INIT_STATE_RECEBIMENTOS = {
  page: 0,
  rowsPerPage: 10,
  tipoBuscaData: 1,
  tipoBusca: 0,
  txBusca: '',
  tipoBuscaStatus: 1,
  dataIni: Moment(new Date()).format('YYYY-MM-DD'),
  dataFim: Moment(new Date()).format('YYYY-MM-DD'),
  credorSelecionado: null,
  grupoSelecionado: null,
  contaSelecionada: null,
  tipoBuscaCredor: '0',
  tipoBuscaFormaPagto: 'TODOS',
  tipoBuscaLocalPagto: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FILTROS_CREDOR: {
      return {
        ...state,
        lista_credor: action.payload,
      };
    }
    case FILTROS_DEVEDOR: {
      return {
        ...state,
        lista_devedores: action.payload,
      };
    }
    case FILTROS_RECEBIMENTOS_LIBERADOS: {
      return {
        ...state,
        lista_recebimentos: action.payload,
      };
    }
    case RESET_REDUX_RECEBIMENTOS_LIBERADOS: {
      return {
        ...state,
        lista_recebimentos: INIT_STATE_RECEBIMENTOS,
      };
    }
    case FILTROS_ACIONAR: {
      return {
        ...state,
        lista_acionar: { ...action.payload },
      };
    }
    case RESET_FILTER_REDUX: {
      return INIT_STATE;
    }
    case FILTROS_ACORDOS_PROGRAMADOS: {
      return {
        ...state,
        lista_acordos_programados: { ...action.payload },
      };
    }
    case RESET_FILTROS_ACORDOS_PROGRAMADOS: {
      return {
        ...state,
        lista_acordos_programados: INIT_STATE.lista_acordos_programados,
      };
    }

    case FILTROS_MINHA_AGENDA: {
      return {
        ...state,
        lista_minha_agenda: { ...action.payload },
      };
    }
    case RESET_FILTROS_MINHA_AGENDA: {
      return {
        ...state,
        lista_minha_agenda: INIT_STATE.lista_minha_agenda,
      };
    }

    default:
      return state;
  }
};
