import React from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { getControleDeAcesso } from 'util/AuthUtils';

import { decrypt } from 'util/cobUtils';

import asyncComponent from '../../util/asyncComponent';
import AlegaPagamento from './financeiro/alegaPagamento';

const erro404 = asyncComponent(() =>
  import('app/routes/extraPages/routes/404')
);

const erro401 = asyncComponent(() =>
  import('app/routes/extraPages/routes/401')
);

const Cobranca = asyncComponent(() => import('./cobranca'));
const Cadastros = asyncComponent(() => import('./cadastros'));
const Backoffice = asyncComponent(() => import('./backoffice'));
const Relatorios = asyncComponent(() => import('./relatorios'));
const Config = asyncComponent(() => import('./config'));
const Financeiro = asyncComponent(() => import('./financeiro'));
const Processo = asyncComponent(() => import('./cobranca/processo'));
const Acionar = asyncComponent(() => import('./cobranca/acionar/ListaAcionar'));
const Agenda = asyncComponent(() => import('./cobranca/agenda/ListaAgenda'));
const Acordo = asyncComponent(() => import('./cobranca/acordo/ListaAcordo'));
const InstrucaoOnline = asyncComponent(() =>
  import('./cobranca/instrucao-online')
);
const Ocorrencias = asyncComponent(() =>
  import('./cobranca/ocorrencias/ListaOcorrencias')
);

const FilaDeAcionamento = asyncComponent(() =>
  import(
    './backoffice/Operacional/ListarFilaDeAcionamento/ListaFilaDeAcionamento'
  )
);
const ReguaDeCobranca = asyncComponent(() =>
  import('./backoffice/ReguaDeCobranca/ListaRegua')
);
const ListarEventosRegua = asyncComponent(() =>
  import('./backoffice/ReguaDeCobranca/Eventos')
);
const ReguaDeCobrancaAcoes = asyncComponent(() =>
  import('./backoffice/ReguaDeCobranca/DetalharRegua')
);

const AcordosLiberados = asyncComponent(() =>
  import('./cobranca/diversos/AcodosLiberados/ListaAcordosLiberados')
);
const ListaPropostasRealizadas = asyncComponent(() =>
  import(`./cobranca/PropostasRealizadas/ListaPropostas`)
);
const TreinamentoSuporte = asyncComponent(() =>
  import('./diversos/WhatsApp/training')
);

const BaixaTitulosLote = asyncComponent(() =>
  import('./backoffice/Ferramentas/BaixaTitulosLote')
);

const MigracaoProcessos = asyncComponent(() =>
  import('./backoffice/Ferramentas/MigracaoProcessos')
);

const EncerrarProcessos = asyncComponent(() =>
  import('./backoffice/Ferramentas/EncerrarProcessos')
);

const PrestacaoModelosImpressao = asyncComponent(() =>
  import('./cadastros/tabelas/PrestacaoModelosImpressao')
);

const Atualizacoes = asyncComponent(() => import('./diversos/Atualizacoes'));
const RelatorioComissao = asyncComponent(() => import('./cobranca/comissao'));

const PrestacaoContas = asyncComponent(() =>
  import('./financeiro/prestacaoContas/ListaPrestacaoContas')
);
const Repasses = asyncComponent(() =>
  import('./financeiro/repasses/ListaRepasses')
);
const NotaFiscal = asyncComponent(() =>
  import('./financeiro/notafiscal/ListaNotaFiscal')
);
const Faturas = asyncComponent(() =>
  import('./financeiro/faturas/ListaFaturas')
);
const Boletos = asyncComponent(() =>
  import('./financeiro/boletos/ListaBoletos')
);
const Pix = asyncComponent(() => import('./financeiro/pix/ListaPix'));

const ModeloEmail = asyncComponent(() =>
  import('./backoffice/AcoesEletronicas/ModelosEmail')
);
const ModeloSMS = asyncComponent(() =>
  import('./backoffice/AcoesEletronicas/ModeloSMS')
);

const CampanhaEmail = asyncComponent(() =>
  import('./backoffice/AcoesEletronicas/CampanhaEmail')
);
const CampanhaBoleto = asyncComponent(() =>
  import('./backoffice/AcoesEletronicas/CampanhaBoleto')
);
const CampanhaSMS = asyncComponent(() =>
  import('./backoffice/AcoesEletronicas/CampanhaSMS')
);

const DiscadorMailling = asyncComponent(() => import('./backoffice/Discador'));
const DiscadorMaillingAuto = asyncComponent(() =>
  import('./backoffice/DiscadorAuto')
);

const AgenteVirtualRobos = asyncComponent(() =>
  import('./backoffice/AgenteVirtual/Robos')
);
const AgenteVirtualParametrizacoes = asyncComponent(() =>
  import('./backoffice/AgenteVirtual')
);
const AgenteVirtualMailling = asyncComponent(() =>
  import('./backoffice/AgenteVirtual/Mailling')
);
const AgenteVirtualPainel = asyncComponent(() =>
  import('./backoffice/AgenteVirtual/Painel')
);

const ControlDesk = asyncComponent(() => import('./backoffice/ControlDesk'));

const DevolucaoTitulos = asyncComponent(() =>
  import('./backoffice/Devolucao/DevolucaoDeTitulos/ListaDevolucaoTitulos')
);

const DevolucaoPorPrazo = asyncComponent(() =>
  import('./backoffice/Devolucao/DevolucaoPrazo/ListaDevolucaoPrazo')
);

const TitulosSeremLiberados = asyncComponent(() =>
  import('./backoffice/Operacional/TitulosSeremLiberados')
);

const Negativacao = asyncComponent(() =>
  import('./backoffice/Operacional/Negativacao')
);

const DocumentosDigitais = asyncComponent(() =>
  import('./backoffice/Operacional/DocumentosDigitais')
);

const TitulosDuplicados = asyncComponent(() =>
  import('./relatorios/03Titulos/TitulosDuplicados/ListaTitulosDuplicados')
);

const ProcessoSemTitulos = asyncComponent(() =>
  import(
    './relatorios/01Processos/ProcessosSemTitulos/ListaProcessosSemTitulos'
  )
);

const ListaSMSEnviados = asyncComponent(() =>
  import('./backoffice/ListaSMSEnviados/ListaSMSEnviados')
);
const ListaEmailsEnviados = asyncComponent(() =>
  import('./backoffice/ListaEmailsEnviados/ListaEmailsEnviados')
);

const AcessosPortalDeNegociacoes = asyncComponent(() =>
  import('./backoffice/PortalDeNegociacao/ListaHistoricoAcessos')
);
const ConfiguracoesPortalDeNegociacoes = asyncComponent(() =>
  import('./backoffice/PortalDeNegociacao/ListaConfiguracoes')
);
const CustomizacaoPortalDoCredor = asyncComponent(() =>
  import('./backoffice/PortalCredor/CustomizacaoPortal')
);
const CustomizacaoPortalDeNegociacoes = asyncComponent(() =>
  import('./backoffice/PortalDeNegociacao/customizacao/CustomizacaoPortal')
);

const HistoricoDeAcesso = asyncComponent(() =>
  import('./backoffice/HistoricoDeAcesso/ListarHistoricoDeAcesso')
);
const HistoricoDeAcoes = asyncComponent(() =>
  import('./backoffice/HistoricoDeAcoes/ListarHistoricoDeAcoes')
);

const HistoricoHigienizacao = asyncComponent(() =>
  import('./backoffice/HistoricoHigienizacao/ListaHistoricoHigienizacao')
);

const ImportacaoTitulos = asyncComponent(() =>
  import('./backoffice/importacoes/Titulos')
);
const HigienizacaoDevedores = asyncComponent(() =>
  import('./backoffice/HigienizacaoDevedor')
);
const ImportacaoTitulosBaixaCarteira = asyncComponent(() =>
  import('./backoffice/importacoes/TitulosBatimento')
);
const ImportacaoTitulosBaixas = asyncComponent(() =>
  import('./backoffice/importacoes/TitulosBaixa')
);
const ImportacaoFonesEmLote = asyncComponent(() =>
  import('./backoffice/importacoes/FonesEmLote')
);
const ManutencaoDeImportacoes = asyncComponent(() =>
  import('./backoffice/importacoes/manutencao')
);
const LotesCredor = asyncComponent(() =>
  import('./backoffice/importacoes/LotesCredor')
);
const ImportacaoOcorrencias = asyncComponent(() =>
  import('./backoffice/importacoes/Ocorrencias')
);

const DistruicaoDeProcessosPlanilha = asyncComponent(() =>
  import('./backoffice/importacoes/DistribuicaoProcessosPlanilhas')
);

const AtualizacaoTagsProcessosPlanilhas = asyncComponent(() =>
  import('./backoffice/importacoes/AtualizacaoTagsProcessoPlanilha')
);

const TrocaDeAcordoNegociador = asyncComponent(() =>
  import('./backoffice/importacoes/AcordosPorPlanilha')
);

const DevedorTitulosExcluidos = asyncComponent(() =>
  import('./backoffice/Ferramentas/DevedorTituloExcluido')
);

const AtualizaStatusProcessoEmLote = asyncComponent(() =>
  import('./backoffice/importacoes/AtualizarStatusProcessoPlanilha')
);

const MetasEmpresa = asyncComponent(() =>
  import('./backoffice/Metas/Dash/MetasEmpresa')
);
const MetasNegociador = asyncComponent(() =>
  import('./backoffice/Metas/Dash/MetasNegociador')
);
const MetasConfiguracoes = asyncComponent(() =>
  import('./backoffice/Metas/ListaConfiguracoes')
);

// Comercial
const ListaProspect = asyncComponent(() =>
  import('./comercial/CadastroProspect')
);
const CadastroProspect = asyncComponent(() =>
  import('./comercial/CadastroProspect/DadosProspect')
);
const ListaOcorrenciaProspects = asyncComponent(() =>
  import('./comercial/HistoricoOcorrencia')
);
const AgendaComercial = asyncComponent(() =>
  import('./comercial/AgendaComercial')
);
const StatusProspects = asyncComponent(() =>
  import('./comercial/StatusProspects')
);
const PerfilProspects = asyncComponent(() =>
  import('./comercial/PerfilProspects')
);
const MotivoCartaoKanban = asyncComponent(() =>
  import('./comercial/MotivoConclusaoCartaoKanban')
);
const Kanban = asyncComponent(() => import('./comercial/Kanban/kanban'));
const Quadros = asyncComponent(() => import('./comercial/Kanban/index'));
const RelConclusaoCartaoKanban = asyncComponent(() =>
  import('./comercial/Kanban/relatorio/CartoesConcluidos')
);
const Comercial = asyncComponent(() => import('./comercial'));

const ImportacaoProspects = asyncComponent(() =>
  import('./comercial/Ferramentas/importacao')
);

const CadastroCredor = asyncComponent(() =>
  import('./cadastros/credores/DadosCredores')
);
const Credores = asyncComponent(() =>
  import('./cadastros/credores/ListaCredores')
);
const Digitalizacao = asyncComponent(() =>
  import('./cadastros/credores/DigitalizacaoCredor')
);

const GrupoCredores = asyncComponent(() => import('./cadastros/GrupoCredores'));

const CadastroDevedor = asyncComponent(() =>
  import('./cadastros/devedores/DadosDevedores')
);
const Devedores = asyncComponent(() =>
  import('./cadastros/devedores/ListaDevedores')
);
const CadastroColaborador = asyncComponent(() =>
  import('./cadastros/colaboradores/DadosColaborador')
);
const Colaboradores = asyncComponent(() =>
  import('./cadastros/colaboradores/ListaColaborador')
);
const GrupoColaboradores = asyncComponent(() =>
  import('./cadastros/GrupoColaboradores')
);
const Fornecedores = asyncComponent(() =>
  import('./cadastros/fornecedores/ListaFornecedores')
);
const CadastroFornecedor = asyncComponent(() =>
  import('./cadastros/fornecedores/DadosFornecedores')
);

const BaixasRealizadas = asyncComponent(() =>
  import('./financeiro/baixas/ListaBaixaRealizadas')
);
const Baixar = asyncComponent(() =>
  import('./financeiro/baixas/BaixarRecebimento')
);
const BaixarSemAcordo = asyncComponent(() =>
  import('./financeiro/baixas/BaixarRecebimentoSemAcordo')
);

const FinanceiroLiberados = asyncComponent(() =>
  import('./financeiro/recebimentos/Liberados/ListaRecebimentos')
);

const ImportacaoRecebimentos = asyncComponent(() =>
  import('./financeiro/recebimentos/importacoes')
);

const BloqueadosIdentificar = asyncComponent(() =>
  import(
    './financeiro/recebimentos/BloqueadosIdentificar/BloqueadosIdentificar'
  )
);

const ModeloDeOcorrencias = asyncComponent(() =>
  import('./cobranca/modelo-ocorrencia')
);

const ImportacaoRecebimentosBaixas = asyncComponent(() =>
  import('./financeiro/ImportacaoRecebimentosBaixas')
);

const MovimentacoesDeContas = asyncComponent(() =>
  import('./financeiro/MovimentacoesDeContas/ListaMovimentacaoContas')
);

const InstrucoesNegociadores = asyncComponent(() =>
  import('./financeiro/InstrucoesNegociador/ListaInstrucaoNegociador')
);

const ContasReceber = asyncComponent(() =>
  import('./financeiro/contasareceber/ListaContasReceber')
);

const ContasPagar = asyncComponent(() =>
  import('./financeiro/contasapagar/ListaContasPagar')
);

const Cheques = asyncComponent(() =>
  import('./financeiro/cheques/ListaCheques')
);

// configuracoes
const ConfigGerais = asyncComponent(() => import('./config/gerais'));
const Integracoes = asyncComponent(() => import('./config/integracoes'));
const NiveisAcesso = asyncComponent(() => import('./config/niveis-acesso'));
const StatusProcesso = asyncComponent(() => import('./config/status-processo'));
const StatusOcorrencia = asyncComponent(() =>
  import('./config/status-ocorrencia')
);
const ClassificacaoTitulo = asyncComponent(() =>
  import('./config/classificacao-titulo')
);
const Email = asyncComponent(() => import('./config/email'));
const LayoutImportacao = asyncComponent(() =>
  import('./config/layout-importacao')
);
const LayoutHigienizacao = asyncComponent(() =>
  import('./config/layout-higienizacao')
);
const ControleDeAcessoSuporte = asyncComponent(() =>
  import('./config/controle-de-acesso-suporte')
);
const ControleDeAcessoIp = asyncComponent(() =>
  import('./config/controle-de-acesso-ip')
);
const ControleDeAcessoHorarios = asyncComponent(() =>
  import('./config/controle-de-acesso-horarios')
);

const PoliticaDeSenhas = asyncComponent(() =>
  import('./config/politicas-de-senha')
);
const LDAP = asyncComponent(() => import('./config/ldap'));

const Automacoes = asyncComponent(() => import('./config/automacoes'));

// cadastros -> tabelas
const Bancos = asyncComponent(() => import('./cadastros/tabelas/Bancos'));
const QualificacaoTelefones = asyncComponent(() =>
  import('./cadastros/tabelas/QualificacaoTelefones')
);
const QualificacaoChamadas = asyncComponent(() =>
  import('./cadastros/tabelas/QualificacaoChamadas')
);
const QualificacaoChamadasDiscador = asyncComponent(() =>
  import('./cadastros/tabelas/QualificacaoChamadasDiscador')
);
const ClassificacaoAcordos = asyncComponent(() =>
  import('./cadastros/tabelas/ClassificacaoAcordos')
);
const ContasBancarias = asyncComponent(() =>
  import('./cadastros/tabelas/ContasBancarias')
);
const CentroDeCusto = asyncComponent(() =>
  import('./cadastros/tabelas/CentroDeCusto')
);
const PlanoDeConta = asyncComponent(() =>
  import('./cadastros/tabelas/PlanoDeConta')
);
const FormaPagto = asyncComponent(() =>
  import('./cadastros/tabelas/FormaPagto')
);
const MotivoPausa = asyncComponent(() =>
  import('./cadastros/tabelas/MotivoPausa')
);
const SegmentoCredor = asyncComponent(() =>
  import('./cadastros/tabelas/SegmentoCredor')
);
const CategoriasCredor = asyncComponent(() =>
  import('./cadastros/tabelas/CategoriasCredor')
);
const SegmentoDevedor = asyncComponent(() =>
  import('./cadastros/tabelas/SegmentoDevedor')
);
const MarcacoesDeProcessos = asyncComponent(() =>
  import('./cadastros/tabelas/MarcacoesDeProcessos')
);
const FasesColaboradores = asyncComponent(() =>
  import('./cadastros/tabelas/FasesColaboradores')
);
const EspecieTitulo = asyncComponent(() =>
  import('./cadastros/tabelas/EspecieTitulo')
);
const CategoriaInstrucao = asyncComponent(() =>
  import('./cadastros/tabelas/CategoriaInstrucao')
);
/*
const PeriodoComissao = asyncComponent(() =>
  import('./cadastros/tabelas/PeriodoComissao')
);
*/
const Empresa = asyncComponent(() => import('./cadastros/tabelas/Empresa'));
const ParametrosAtraso = asyncComponent(() =>
  import('./cadastros/tabelas/CampanhaProcesso')
);
const ParametrosCarteira = asyncComponent(() =>
  import('./cadastros/tabelas/ParametrosCarteira')
);
const CampanhaDesconto = asyncComponent(() =>
  import('./cadastros/tabelas/CampanhaDesconto')
);
const NegativacaoMotivoRetirada = asyncComponent(() =>
  import('./cadastros/tabelas/NegativacaoMotivoRetirada')
);
const NegativacaoNaturezaInclusao = asyncComponent(() =>
  import('./cadastros/tabelas/NegativacaoNaturezaInclusao')
);

// relatórios
const GeradorDeDocumentos = asyncComponent(() =>
  import('./relatorios/gerador-documentos')
);

const ConsultaGeral = asyncComponent(() =>
  import('./relatorios/01Processos/01.01ConsultaGeral')
);
const listaDeProcessos = asyncComponent(() =>
  import('./relatorios/01Processos/01.02ListaDeProcessos')
);
const StatusProcxCredor = asyncComponent(() =>
  import('./relatorios/01Processos/01.04StatusProcXCredor')
);
const ProcessosXNegociador = asyncComponent(() =>
  import('./relatorios/01Processos/01.05ProcessoXNegociador')
);
const ProcessosXRegiao = asyncComponent(() =>
  import('./relatorios/01Processos/01.06ProcessosXRegiao')
);

const listaDeTitulos = asyncComponent(() =>
  import('./relatorios/03Titulos/03.01ListadeDeTitulos')
);
const listaDeImportacoes = asyncComponent(() =>
  import('./relatorios/03Titulos/03.02ListaDeImportacoes')
);

const listaDeDevedores = asyncComponent(() =>
  import('./relatorios/05Devedores')
);

const RelProdutividade = asyncComponent(() =>
  import('./relatorios/06Negociador/06.01Produtividade')
);
const RelPausas = asyncComponent(() =>
  import('./relatorios/06Negociador/06.02Pausas')
);

const rel0701 = asyncComponent(() =>
  import('./relatorios/07ConsultorComercial/07.01TitulosCaptadosMensal')
);
const rel0703 = asyncComponent(() =>
  import('./relatorios/07ConsultorComercial/07.03TitulosCaptadosFaixaVencto')
);
const rel0704 = asyncComponent(() =>
  import('./relatorios/07ConsultorComercial/07.04CredoresNEnviamBorderosXDias')
);

const listaRelContasReceber = asyncComponent(() =>
  import('./relatorios/08Financeiro/08.01ContasAReceber')
);
const listaRelContasPagar = asyncComponent(() =>
  import('./relatorios/08Financeiro/08.02ContasAPagar')
);
const listaRelMovContas = asyncComponent(() =>
  import('./relatorios/08Financeiro/08.03MovContas')
);

const listaRemuneracaoPorCredor = asyncComponent(() =>
  import('./relatorios/09Gerencial/09.02RemuneracaoCredor')
);
const listaRemuneracaoPorNegociador = asyncComponent(() =>
  import('./relatorios/09Gerencial/09.03RemunuracaoNegociador')
);

const ModalidadeContrato = asyncComponent(() =>
  import('./cadastros/tabelas/ModalidadeContrato')
);
const ComissaoEscalonada = asyncComponent(() =>
  import('./cadastros/tabelas/ComissaoEscalonada')
);

const RemessaRetornoEntradaConfirmada = asyncComponent(() =>
  import('./relatorios/RemessaRetorno/EntradaConfirmada')
);

const RemessaRetornoLiquidacoes = asyncComponent(() =>
  import('./relatorios/RemessaRetorno/Liquidacoes')
);

const RemessaRetornoDevolucoes = asyncComponent(() =>
  import('./relatorios/RemessaRetorno/Devolucoes')
);

const RemessaRetornoOcorrenciasRel = asyncComponent(() =>
  import('./relatorios/RemessaRetorno/Ocorrencias')
);

const RelatoriosEspecificos = asyncComponent(() =>
  import('./relatorios/Especificos')
);

const RelatoriosDocumentos = asyncComponent(() =>
  import('./relatorios/Documentos')
);

const Produtos = asyncComponent(() => import('./config/produtos'));

const Ferramentas0501 = asyncComponent(() =>
  import('./backoffice/Ferramentas/05/0501')
);
const Ferramentas0502 = asyncComponent(() =>
  import('./backoffice/Ferramentas/05/0502')
);

const ImportacaoRecebimentosGera = asyncComponent(() =>
  import('./backoffice/Ferramentas/188/ImportacaoRecebimentosGera')
);

const ImportacaoCRMs = asyncComponent(() =>
  import('./backoffice/Ferramentas/ImportacaoCRMs/ImportacaoCRMs')
);

const AutomacoesBackofficeRelatorios = asyncComponent(() =>
  import('./backoffice/Automacoes/Relatorios')
);
const AutomacoesBackofficeDiversos = asyncComponent(() =>
  import('./backoffice/Automacoes/Diversos')
);

const ListarEventosGeral = asyncComponent(() =>
  import('./backoffice/Automacoes/Eventos')
);

const PrivateRoute = ({ component: Component, codigo, ...rest }) => {
  try {
    // consulta no localstorage
    let authUserInfo = localStorage.getItem('access');
    authUserInfo = decrypt(authUserInfo);
    authUserInfo = JSON.parse(authUserInfo);

    return (
      <Route
        {...rest}
        render={(props) => {
          const acesso = getControleDeAcesso(codigo, authUserInfo);

          if (acesso == false) {
            return (
              <Redirect
                to={{
                  pathname: '/app/acesso-negado',
                  state: { from: props.location },
                }}
              />
            );
          }

          // autorizado
          return <Component {...props} />;
        }}
      />
    );
  } catch (error) {
    return (
      <Route
        {...rest}
        render={(props) => {
          return (
            <Redirect
              to={{
                pathname: '/app/acesso-negado',
                state: { from: props.location },
              }}
            />
          );
        }}
      />
    );
  }
};

const Routes = ({ match }) => (
  <Switch>
    {/** *********************** COBRANCA ************************************** */}

    <PrivateRoute
      path={`${match.url}/cob/devedores/cadastro/:id`}
      component={CadastroDevedor}
      codigo="cad_devedor_a"
    />
    <PrivateRoute
      path={`${match.url}/cob/devedores/cadastro`}
      component={CadastroDevedor}
      codigo="cad_devedor_c"
    />
    <PrivateRoute
      path={`${match.url}/cob/devedores`}
      component={Devedores}
      codigo="cad_devedor_v"
    />
    <Route path={`${match.url}/cob/processo/:id`} component={Processo} />

    <PrivateRoute
      path={`${match.url}/cob/acionar`}
      component={Acionar}
      codigo="cob_acionar"
    />
    <PrivateRoute
      path={`${match.url}/cob/agenda`}
      component={Agenda}
      codigo="cob_agenda_ret"
    />
    <PrivateRoute
      path={`${match.url}/cob/acordo`}
      component={Acordo}
      codigo="cob_acordos_prog"
    />
    <PrivateRoute
      path={`${match.url}/cob/ocorrencias`}
      component={Ocorrencias}
      codigo="rel_02"
    />
    <PrivateRoute
      path={`${match.url}/cob/instrucao-online`}
      component={InstrucaoOnline}
      codigo="instrucao_online"
    />
    <PrivateRoute
      path={`${match.url}/cob/boletos`}
      component={Boletos}
      codigo="fin_bol_vis"
    />
    <PrivateRoute
      path={`${match.url}/cob/pix`}
      component={Pix}
      codigo="cob_pix"
    />
    <PrivateRoute
      path={`${match.url}/cob/bloqueados-identificar`}
      component={BloqueadosIdentificar}
      codigo="cob_recident"
    />
    <PrivateRoute
      path={`${match.url}/cob/modelos-ocorrencias`}
      component={ModeloDeOcorrencias}
      codigo="cob_modelo_oco"
    />
    <PrivateRoute
      path={`${match.url}/cob/acordos-liberados`}
      component={AcordosLiberados}
      codigo="cob_acordos_lib"
    />
    <PrivateRoute
      path={`${match.url}/cob/propostas-realizadas`}
      component={ListaPropostasRealizadas}
      codigo="cob_propostas"
    />
    <PrivateRoute
      path={`${match.url}/cob/relatorio-comissao`}
      component={RelatorioComissao}
      codigo="cob_comissao"
    />
    <Route path={`${match.url}/cob/inicio`} component={Cobranca} />
    <Route
      path={`${match.url}/cob/suporte-tecnico`}
      component={ControleDeAcessoSuporte}
    />
    <Route path={`${match.url}/cob`} component={Cobranca} />

    {/** *********************** RELATORIOS ************************************** */}
    <PrivateRoute
      path={`${match.url}/rel/criacao-documentos`}
      component={GeradorDeDocumentos}
      codigo="rel_gerador"
    />

    {/* Ocorrencias - 2.01 */}
    <PrivateRoute
      path={`${match.url}/rel/lista-de-ocorrencias/`}
      component={Ocorrencias}
      codigo="rel_02"
    />
    {/* Ocorrencias - 2.02 */}
    <PrivateRoute
      path={`${match.url}/rel/acordos-programados/`}
      component={Acordo}
      codigo="rel_02"
    />

    {/* Processos - 1.01 */}
    <PrivateRoute
      path={`${match.url}/rel/consulta-geral`}
      component={ConsultaGeral}
      codigo="rel_01"
    />
    {/* Processos - 1.02 */}
    <PrivateRoute
      path={`${match.url}/rel/lista-de-processos`}
      component={listaDeProcessos}
      codigo="rel_01"
    />
    {/* Processos - 1.04 */}
    <PrivateRoute
      path={`${match.url}/rel/processos-x-credor`}
      component={StatusProcxCredor}
      codigo="rel_01"
    />
    {/* Processos - 1.05 */}
    <PrivateRoute
      path={`${match.url}/rel/processos-x-negociador`}
      component={ProcessosXNegociador}
      codigo="rel_01"
    />
    {/* Processos - 1.06 */}
    <PrivateRoute
      path={`${match.url}/rel/processos-x-regiao`}
      component={ProcessosXRegiao}
      codigo="rel_01"
    />
    {/* Processos - 1.07 */}
    <PrivateRoute
      path={`${match.url}/rel/processos-sem-titulos`}
      component={ProcessoSemTitulos}
      codigo="rel_01"
    />

    {/* Titulos - 3.01 */}
    <PrivateRoute
      path={`${match.url}/rel/lista-de-titulos`}
      component={listaDeTitulos}
      codigo="rel_03"
    />
    {/* Titulos - 3.02 */}
    <PrivateRoute
      path={`${match.url}/rel/lista-de-importacoes`}
      component={listaDeImportacoes}
      codigo="rel_03"
    />
    {/* Titulos - 3.03 */}
    <PrivateRoute
      path={`${match.url}/rel/titulos-duplicados`}
      component={TitulosDuplicados}
      codigo="rel_03"
    />

    {/* Consultor/Comercial - 7.01 */}
    <PrivateRoute
      path={`${match.url}/rel/titulos-captados-mensal`}
      component={rel0701}
      codigo="rel_07"
    />
    {/* Consultor/Comercial - 7.03 */}
    <PrivateRoute
      path={`${match.url}/rel/titulos-captados-faixa`}
      component={rel0703}
      codigo="rel_07"
    />
    {/* Consultor/Comercial - 7.04 */}
    <PrivateRoute
      path={`${match.url}/rel/credores-nao-enviam-borderos-x-dias`}
      component={rel0704}
      codigo="rel_07"
    />

    {/* Financeiro - 8.01 */}
    <PrivateRoute
      path={`${match.url}/rel/contas-a-receber`}
      component={listaRelContasReceber}
      codigo="rel_08"
    />
    <PrivateRoute
      path={`${match.url}/rel/contas-a-pagar`}
      component={listaRelContasPagar}
      codigo="rel_08"
    />
    <PrivateRoute
      path={`${match.url}/rel/mov-contas`}
      component={listaRelMovContas}
      codigo="rel_08"
    />

    <PrivateRoute
      path={`${match.url}/rel/lista-de-devedores`}
      component={listaDeDevedores}
      codigo="rel_05"
    />

    {/* Negociador - 06.01 */}
    <PrivateRoute
      path={`${match.url}/rel/produtividade`}
      component={RelProdutividade}
      codigo="rel_06"
    />
    {/* Negociador - 06.02 */}
    <PrivateRoute
      path={`${match.url}/rel/pausas`}
      component={RelPausas}
      codigo="rel_06"
    />

    {/* Gerencial - 9.02 */}
    <PrivateRoute
      path={`${match.url}/rel/lista-remuneracao-credor`}
      component={listaRemuneracaoPorCredor}
      codigo="rel_09"
    />
    {/* Gerencial - 9.03 */}
    <PrivateRoute
      path={`${match.url}/rel/lista-remuneracao-negociador`}
      component={listaRemuneracaoPorNegociador}
      codigo="rel_09"
    />
    {/** Remessa/Retorno */}
    <PrivateRoute
      path={`${match.url}/rel/entrada-confirmada`}
      component={RemessaRetornoEntradaConfirmada}
      codigo="rel"
    />
    <PrivateRoute
      path={`${match.url}/rel/liquidacoes`}
      component={RemessaRetornoLiquidacoes}
      codigo="rel"
    />
    <PrivateRoute
      path={`${match.url}/rel/devolucoes`}
      component={RemessaRetornoDevolucoes}
      codigo="rel"
    />
    <PrivateRoute
      path={`${match.url}/rel/ocorrencias`}
      component={RemessaRetornoOcorrenciasRel}
      codigo="rel"
    />
    <PrivateRoute
      path={`${match.url}/rel/personalizados`}
      component={RelatoriosEspecificos}
      codigo="rel"
    />
    <PrivateRoute
      path={`${match.url}/rel/pdf`}
      component={RelatoriosDocumentos}
      codigo="rel"
    />

    <PrivateRoute
      path={`${match.url}/rel/inicio`}
      component={Relatorios}
      codigo="rel"
    />
    <PrivateRoute
      path={`${match.url}/rel`}
      component={Relatorios}
      codigo="rel"
    />

    {/** *********************** BACKOFFICE ************************************** */}
    <PrivateRoute
      path={`${match.url}/backof/updates`}
      component={Atualizacoes}
      codigo="back_controldesk"
    />
    <PrivateRoute
      path={`${match.url}/backof/controldesk`}
      component={ControlDesk}
      codigo="back_controldesk"
    />
    <PrivateRoute
      path={`${match.url}/backof/devolucao/devolucao-por-prazo`}
      component={DevolucaoPorPrazo}
      codigo="back_devolucao"
    />
    <PrivateRoute
      path={`${match.url}/backof/devolucao/devolucao-titulos`}
      component={DevolucaoTitulos}
      codigo="back_devolucao"
    />
    <PrivateRoute
      path={`${match.url}/backof/regua-de-cobranca/acoes/:id`}
      component={ReguaDeCobrancaAcoes}
      codigo="back_regua_cobranca"
    />
    <PrivateRoute
      path={`${match.url}/backof/regua-de-cobranca/config`}
      component={ReguaDeCobranca}
      codigo="back_regua_cobranca"
    />
    <PrivateRoute
      path={`${match.url}/backof/regua-de-cobranca/eventos`}
      component={ListarEventosRegua}
      codigo="back_regua_cobranca"
    />
    <PrivateRoute
      path={`${match.url}/backof/operacional/fila-acionamento`}
      component={FilaDeAcionamento}
      codigo="back_fila_acionamento"
    />
    <PrivateRoute
      path={`${match.url}/backof/operacional/acordos-liberados`}
      component={AcordosLiberados}
      codigo="back_liberacao_acordo"
    />
    <PrivateRoute
      path={`${match.url}/backof/operacional/titulos-serem-liberados`}
      component={TitulosSeremLiberados}
      codigo="back_titulos_a_liberar"
    />
    <PrivateRoute
      path={`${match.url}/backof/operacional/negativacao`}
      component={Negativacao}
      codigo="back_negativacao"
    />
    <PrivateRoute
      path={`${match.url}/backof/operacional/documentos-digitais`}
      component={DocumentosDigitais}
      codigo="back_documentos_digitais"
    />
    <PrivateRoute
      path={`${match.url}/backof/portal/acessos`}
      component={AcessosPortalDeNegociacoes}
      codigo="back_portal_neg"
    />
    <PrivateRoute
      path={`${match.url}/backof/portal/customizacao`}
      component={CustomizacaoPortalDeNegociacoes}
      codigo="back_portal_neg"
    />
    <PrivateRoute
      path={`${match.url}/backof/portal/config`}
      component={ConfiguracoesPortalDeNegociacoes}
      codigo="back_portal_neg"
    />
    <PrivateRoute
      path={`${match.url}/backof/portal-credor/acessos`}
      component={HistoricoDeAcesso}
      codigo="back_portal_neg"
    />
    <PrivateRoute
      path={`${match.url}/backof/portal-credor/customizacao`}
      component={CustomizacaoPortalDoCredor}
      codigo="back_portal_credor"
    />
    <PrivateRoute
      path={`${match.url}/backof/historico-de-acesso`}
      component={HistoricoDeAcesso}
      codigo="back_hist_acessos"
    />
    <PrivateRoute
      path={`${match.url}/backof/historico-de-acoes`}
      component={HistoricoDeAcoes}
      codigo="back_hist_acessos"
    />
    <PrivateRoute
      path={`${match.url}/backof/historico-higienizacao`}
      component={HistoricoHigienizacao}
      codigo="back_controldesk"
    />
    <PrivateRoute
      path={`${match.url}/backof/sms-enviados`}
      component={ListaSMSEnviados}
      codigo="back_controldesk"
    />
    <PrivateRoute
      path={`${match.url}/backof/emails-enviados`}
      component={ListaEmailsEnviados}
      codigo="back_controldesk"
    />
    <PrivateRoute
      path={`${match.url}/backof/higienizacao-devedores`}
      component={HigienizacaoDevedores}
      codigo="back_higienizacao_dev"
    />
    <PrivateRoute
      path={`${match.url}/backof/tools/baixas-por-planilha`}
      component={ImportacaoTitulosBaixas}
      codigo="back_baixas_titulos_planilha"
    />
    <PrivateRoute
      path={`${match.url}/backof/importacoes/Titulos`}
      component={ImportacaoTitulos}
      codigo="back_importacoes_tit"
    />
    <PrivateRoute
      path={`${match.url}/backof/importacoes/titulos-baixa`}
      component={ImportacaoTitulosBaixaCarteira}
      codigo="back_importacoes_tit"
    />
    <PrivateRoute
      path={`${match.url}/backof/importacoes/titulos-manutencao`}
      component={ManutencaoDeImportacoes}
      codigo="back_manutencao_tit"
    />
    <PrivateRoute
      path={`${match.url}/backof/importacoes/lotes-credor`}
      component={LotesCredor}
      codigo="back_importacoes_tit"
    />
    <PrivateRoute
      path={`${match.url}/backof/importacoes/ocorrencias`}
      component={ImportacaoOcorrencias}
      codigo="back_importacao_oco"
    />
    <PrivateRoute
      path={`${match.url}/backof/tools/distribuicao-processos-planilha`}
      component={DistruicaoDeProcessosPlanilha}
      codigo="back_distribuicao_processo_planilha"
    />
    <PrivateRoute
      path={`${match.url}/backof/tools/atualizacao-tags-processos-planilha`}
      component={AtualizacaoTagsProcessosPlanilhas}
      codigo="tab_tags_marcacoes"
    />
    <PrivateRoute
      path={`${match.url}/backof/tools/troca-negociador-acordo`}
      component={TrocaDeAcordoNegociador}
      codigo="back_alterar_neg_acordo_planilha"
    />
    <PrivateRoute
      path={`${match.url}/backof/tools/atualiza-status-processo-em-lote`}
      component={AtualizaStatusProcessoEmLote}
      codigo="back_atualizar_status_planilha"
    />
    <PrivateRoute
      path={`${match.url}/backof/tools/devedores-titulos-excluidos`}
      component={DevedorTitulosExcluidos}
      codigo="back_devedor_titulo_excluido"
    />
    <PrivateRoute
      path={`${match.url}/backof/acoeseletronicas/modelo-email`}
      component={ModeloEmail}
      codigo="back_acoes_email"
    />
    <PrivateRoute
      path={`${match.url}/backof/acoeseletronicas/modelo-sms`}
      component={ModeloSMS}
      codigo="back_acoes_sms"
    />
    <PrivateRoute
      path={`${match.url}/backof/acoeseletronicas/campanha-email`}
      component={CampanhaEmail}
      codigo="back_acoes_email"
    />
    <PrivateRoute
      path={`${match.url}/backof/acoeseletronicas/campanha-sms`}
      component={CampanhaSMS}
      codigo="back_acoes_sms"
    />
    <PrivateRoute
      path={`${match.url}/backof/acoeseletronicas/campanha-boleto`}
      component={CampanhaBoleto}
      codigo="back_acoes_boletos"
    />
    <PrivateRoute
      path={`${match.url}/backof/discador/mailling`}
      component={DiscadorMailling}
      codigo="back_discador"
    />
    <PrivateRoute
      path={`${match.url}/backof/discador/mailling-auto`}
      component={DiscadorMaillingAuto}
      codigo="back_discador"
    />

    <PrivateRoute
      path={`${match.url}/backof/agente-virtual/config`}
      component={AgenteVirtualParametrizacoes}
      codigo="back_agente_virtual"
    />
    <PrivateRoute
      path={`${match.url}/backof/agente-virtual/mailling`}
      component={AgenteVirtualMailling}
      codigo="back_agente_virtual"
    />
    <PrivateRoute
      path={`${match.url}/backof/agente-virtual/agentes`}
      component={AgenteVirtualRobos}
      codigo="back_agente_virtual"
    />
    <PrivateRoute
      path={`${match.url}/backof/agente-virtual/painel`}
      component={AgenteVirtualPainel}
      codigo="back_agente_virtual"
    />

    <PrivateRoute
      path={`${match.url}/backof/metas-empresa`}
      component={MetasEmpresa}
      codigo="back_controldesk"
    />
    <PrivateRoute
      path={`${match.url}/backof/metas-negociador`}
      component={MetasNegociador}
      codigo="back_controldesk"
    />
    <PrivateRoute
      path={`${match.url}/backof/metas/config`}
      component={MetasConfiguracoes}
      codigo="back_controldesk"
    />

    <PrivateRoute
      path={`${match.url}/backof/tools/0501`}
      component={Ferramentas0501}
      codigo="back_controldesk"
    />
    <PrivateRoute
      path={`${match.url}/backof/tools/0502`}
      component={Ferramentas0502}
      codigo="back_controldesk"
    />
    <PrivateRoute
      path={`${match.url}/backof/tools/importar-recebimentos-gera`}
      component={ImportacaoRecebimentosGera}
      codigo="back_controldesk"
    />

    <PrivateRoute
      path={`${match.url}/backof/tools/importacao-crms-diversos`}
      component={ImportacaoCRMs}
      codigo="back_importacoes_tit"
    />

    <PrivateRoute
      path={`${match.url}/backof/tools/baixa-titulos-lote`}
      component={BaixaTitulosLote}
      codigo="back_baixas_titulos_lote"
    />

    <PrivateRoute
      path={`${match.url}/backof/tools/migrar-processos`}
      component={MigracaoProcessos}
      codigo="back_migrar_processos"
    />

    <PrivateRoute
      path={`${match.url}/backof/tools/encerrar-processos`}
      component={EncerrarProcessos}
      codigo="back_encerrar_processo_sem_saldo"
    />

    <PrivateRoute
      component={AutomacoesBackofficeRelatorios}
      path={`${match.url}/backof/automacoes/relatorios`}
      codigo="back_automacoes"
    />

    <PrivateRoute
      component={AutomacoesBackofficeDiversos}
      path={`${match.url}/backof/automacoes/diversos`}
      codigo="back_automacoes"
    />

    <PrivateRoute
      path={`${match.url}/backof/automacoes/eventos`}
      component={ListarEventosGeral}
      codigo="back_automacoes"
    />

    <PrivateRoute
      path={`${match.url}/backof/tools/atualizacao-telefones-planilha`}
      component={ImportacaoFonesEmLote}
      codigo="back_atualiza_fones"
    />

    <PrivateRoute
      path={`${match.url}/backof/inicio`}
      component={Backoffice}
      codigo="back"
    />
    <PrivateRoute
      path={`${match.url}/backof`}
      component={Backoffice}
      codigo="back"
    />

    {/** *********************** COMERCIAL ************************************** */}

    <PrivateRoute
      path={`${match.url}/com/prospect/:id`}
      component={CadastroProspect}
      codigo="comercial"
    />
    <PrivateRoute
      path={`${match.url}/com/prospect/`}
      component={CadastroProspect}
      codigo="comercial"
    />
    <PrivateRoute
      path={`${match.url}/com/prospects`}
      component={ListaProspect}
      codigo="comercial"
    />
    <PrivateRoute
      path={`${match.url}/com/historico-ocorrencia`}
      component={ListaOcorrenciaProspects}
      codigo="comercial"
    />
    <PrivateRoute
      path={`${match.url}/com/agenda-comercial`}
      component={AgendaComercial}
      codigo="comercial"
    />
    <PrivateRoute
      path={`${match.url}/com/status-prospects`}
      component={StatusProspects}
      codigo="comercial"
    />
    <PrivateRoute
      path={`${match.url}/com/perfil-prospects`}
      component={PerfilProspects}
      codigo="comercial"
    />
    <PrivateRoute
      path={`${match.url}/com/motivo-conclusao-kanban`}
      component={MotivoCartaoKanban}
      codigo="comercial"
    />
    <PrivateRoute
      path={`${match.url}/com/kanban/:id`}
      component={Kanban}
      codigo="comercial"
    />
    <PrivateRoute
      path={`${match.url}/com/kanban`}
      component={Quadros}
      codigo="comercial"
    />
    <PrivateRoute
      path={`${match.url}/com/rel-kanban-conclusao-cartoes`}
      component={RelConclusaoCartaoKanban}
      codigo="comercial"
    />
    <PrivateRoute
      path={`${match.url}/com/inicio`}
      component={Comercial}
      codigo="comercial"
    />
    <PrivateRoute
      path={`${match.url}/com/prospects-importacoes`}
      component={ImportacaoProspects}
      codigo="comercial"
    />
    <PrivateRoute
      path={`${match.url}/com`}
      component={Comercial}
      codigo="comercial"
    />

    {/** *********************** CADASTROS ************************************** */}

    <PrivateRoute
      path={`${match.url}/cad/credores/digitalizacao`}
      component={Digitalizacao}
      codigo="cad_credor_v"
    />

    <PrivateRoute
      path={`${match.url}/cad/credores/cadastro/:id`}
      component={CadastroCredor}
      codigo="cad_credor_v_a"
    />
    <PrivateRoute
      path={`${match.url}/cad/credores/cadastro`}
      component={CadastroCredor}
      codigo="cad_credor_c"
    />
    <PrivateRoute
      path={`${match.url}/cad/credores`}
      component={Credores}
      codigo="cad_credor_v"
    />
    <PrivateRoute
      path={`${match.url}/cad/grupo-credores`}
      component={GrupoCredores}
      codigo="cad_credor_grupo"
    />
    <PrivateRoute
      path={`${match.url}/cad/devedores/cadastro/:id`}
      component={CadastroDevedor}
      codigo="cad_devedor_a"
    />
    <PrivateRoute
      path={`${match.url}/cad/devedores/cadastro`}
      component={CadastroDevedor}
      codigo="cad_devedor_c"
    />
    <PrivateRoute
      path={`${match.url}/cad/devedores`}
      component={Devedores}
      codigo="cad_devedor_v"
    />

    <PrivateRoute
      path={`${match.url}/cad/colaboradores/cadastro/:id`}
      component={CadastroColaborador}
      codigo="cad_colaborador_a"
    />
    <PrivateRoute
      path={`${match.url}/cad/colaboradores/cadastro`}
      component={CadastroColaborador}
      codigo="cad_colaborador_c"
    />
    <PrivateRoute
      path={`${match.url}/cad/colaboradores`}
      component={Colaboradores}
      codigo="cad_colaborador_v"
    />
    <PrivateRoute
      path={`${match.url}/cad/equipes`}
      component={GrupoColaboradores}
      codigo="cad_colaborador_grupo"
    />

    <PrivateRoute
      path={`${match.url}/cad/fornecedores/cadastro/:id`}
      component={CadastroFornecedor}
      codigo="cad_fornecedor_a"
    />
    <PrivateRoute
      path={`${match.url}/cad/fornecedores/cadastro`}
      component={CadastroFornecedor}
      codigo="cad_fornecedor_c"
    />
    <PrivateRoute
      path={`${match.url}/cad/fornecedores`}
      component={Fornecedores}
      codigo="cad_fornecedor_v"
    />

    <PrivateRoute
      path={`${match.url}/cad/tabelas/bancos`}
      component={Bancos}
      codigo="tab_bancos"
    />
    <PrivateRoute
      path={`${match.url}/cad/tabelas/qualificacao-telefones`}
      component={QualificacaoTelefones}
      codigo="tabelas_discador"
    />
    <PrivateRoute
      path={`${match.url}/cad/tabelas/qualificacao-chamadas`}
      component={QualificacaoChamadas}
      codigo="tabelas_discador"
    />
    <PrivateRoute
      path={`${match.url}/cad/tabelas/qualificacao-chamadas-discador`}
      component={QualificacaoChamadasDiscador}
      codigo="tabelas_discador"
    />
    <PrivateRoute
      path={`${match.url}/cad/tabelas/classificacao-acordos`}
      component={ClassificacaoAcordos}
      codigo="tab_classifica_acordo"
    />
    <PrivateRoute
      path={`${match.url}/cad/tabelas/contas-bancarias`}
      component={ContasBancarias}
      codigo="tab_contas"
    />
    <PrivateRoute
      path={`${match.url}/cad/tabelas/centro-custo`}
      component={CentroDeCusto}
      codigo="tab_custo"
    />
    <PrivateRoute
      path={`${match.url}/cad/tabelas/plano-conta`}
      component={PlanoDeConta}
      codigo="tab_plano_conta"
    />
    <PrivateRoute
      path={`${match.url}/cad/tabelas/forma-pagto`}
      component={FormaPagto}
      codigo="tab_forma_pagto"
    />
    <PrivateRoute
      path={`${match.url}/cad/tabelas/empresa`}
      component={Empresa}
      codigo="tab_empresas"
    />
    <PrivateRoute
      path={`${match.url}/cad/tabelas/motivo-pausa`}
      component={MotivoPausa}
      codigo="tabelas_discador"
    />
    <PrivateRoute
      path={`${match.url}/cad/tabelas/negativacao-motivos-retirada`}
      component={NegativacaoMotivoRetirada}
      codigo="tab_retirada_negativacao"
    />
    <PrivateRoute
      path={`${match.url}/cad/tabelas/negativacao-natureza-inclusao`}
      component={NegativacaoNaturezaInclusao}
      codigo="tab_inclusao_negativacao"
    />
    <PrivateRoute
      path={`${match.url}/cad/tabelas/segmento-credor`}
      component={SegmentoCredor}
      codigo="tab_segmento_cred"
    />
    <PrivateRoute
      path={`${match.url}/cad/tabelas/categorias-credor`}
      component={CategoriasCredor}
      codigo="tab_categoria_cred"
    />
    <PrivateRoute
      path={`${match.url}/cad/tabelas/segmento-devedor`}
      component={SegmentoDevedor}
      codigo="tab_segmento_dev"
    />
    <PrivateRoute
      path={`${match.url}/cad/tabelas/marcacoes-de-processo`}
      component={MarcacoesDeProcessos}
      codigo="tab_tags_marcacoes"
    />
    <PrivateRoute
      path={`${match.url}/cad/tabelas/especie-titulo`}
      component={EspecieTitulo}
      codigo="tab_especie_titulo"
    />
    <PrivateRoute
      path={`${match.url}/cad/tabelas/fases-processo`}
      component={FasesColaboradores}
      codigo="tab_fases"
    />
    <PrivateRoute
      path={`${match.url}/cad/tabelas/categoria-instrucao`}
      component={CategoriaInstrucao}
      codigo="tab_categoria_instrucao"
    />
    {/*
    <Route
      path={`${match.url}/cad/tabelas/periodo-comissao`}
      component={PeriodoComissao}
    />
    */}
    <PrivateRoute
      path={`${match.url}/cad/tabelas/parametros-atraso`}
      component={ParametrosAtraso}
      codigo="tab_param_cob"
    />
    <PrivateRoute
      path={`${match.url}/cad/tabelas/parametros-carteira`}
      component={ParametrosCarteira}
      codigo="tab_param_car"
    />
    <PrivateRoute
      path={`${match.url}/cad/tabelas/campanhas-desconto`}
      component={CampanhaDesconto}
      codigo="tab_camp_desc"
    />
    <PrivateRoute
      path={`${match.url}/cad/tabelas/modalidade-contrato`}
      component={ModalidadeContrato}
      codigo="tab_modal_contr"
    />
    <PrivateRoute
      path={`${match.url}/cad/tabelas/comissao-escalonada`}
      component={ComissaoEscalonada}
      codigo="tab_comissao_escalonada"
    />
    <PrivateRoute
      path={`${match.url}/cad/tabelas/modelos-prestacao`}
      component={PrestacaoModelosImpressao}
      codigo="tab_modelos_pc"
    />
    <PrivateRoute
      path={`${match.url}/cad/inicio`}
      component={Cadastros}
      codigo="cad"
    />
    <PrivateRoute
      path={`${match.url}/cad`}
      component={Cadastros}
      codigo="cad"
    />

    {/** *********************** FINANCEIRO ************************************** */}

    <PrivateRoute
      path={`${match.url}/fin/baixas-realizadas`}
      component={BaixasRealizadas}
      codigo="fin_baixas_vis"
    />
    <PrivateRoute
      path={`${match.url}/fin/baixar/:id`}
      component={Baixar}
      codigo="fin_rec_baixar"
    />
    <PrivateRoute
      path={`${match.url}/fin/baixar-sem-acordo/:id`}
      component={BaixarSemAcordo}
      codigo="fin_rec_baixar"
    />
    <PrivateRoute
      path={`${match.url}/fin/recebimentos/liberados-baixa`}
      component={FinanceiroLiberados}
      codigo="fin_rec_vis"
    />
    <PrivateRoute
      path={`${match.url}/fin/recebimentos/importacoes`}
      component={ImportacaoRecebimentos}
      codigo="fin_rec_cad"
    />
    <PrivateRoute
      path={`${match.url}/fin/importar-recebimentos-baixas`}
      component={ImportacaoRecebimentosBaixas}
      codigo="fin_rec_bloq_vis"
    />
    <PrivateRoute
      path={`${match.url}/fin/prestacao-contas`}
      component={PrestacaoContas}
      codigo="fin_pc_vis"
    />
    <PrivateRoute
      path={`${match.url}/fin/repasses`}
      component={Repasses}
      codigo="fin_rep_vis"
    />
    <PrivateRoute
      path={`${match.url}/fin/nota-fiscal`}
      component={NotaFiscal}
      codigo="fin_nf"
    />
    <PrivateRoute
      path={`${match.url}/fin/faturas`}
      component={Faturas}
      codigo="fin_fat_vis"
    />
    <PrivateRoute
      path={`${match.url}/fin/boletos`}
      component={Boletos}
      codigo="fin_bol_vis"
    />
    <PrivateRoute
      path={`${match.url}/fin/pix`}
      component={Pix}
      codigo="fin_pix_vis"
    />
    <PrivateRoute
      path={`${match.url}/fin/cheques`}
      component={Cheques}
      codigo="fin_cheq_vis"
    />
    <PrivateRoute
      path={`${match.url}/fin/recebimentos/bloqueados-identificar`}
      component={BloqueadosIdentificar}
      codigo="fin_rec_bloq_vis"
    />
    <PrivateRoute
      path={`${match.url}/fin/mov-contas`}
      component={MovimentacoesDeContas}
      codigo="fin_movc_vis"
    />
    <PrivateRoute
      path={`${match.url}/fin/contas-a-receber`}
      component={ContasReceber}
      codigo="fin_crec_vis"
    />
    <PrivateRoute
      path={`${match.url}/fin/contas-a-pagar`}
      component={ContasPagar}
      codigo="fin_cpag_vis"
    />
    <PrivateRoute
      path={`${match.url}/fin/portal-alega-pagamento`}
      component={AlegaPagamento}
      codigo="fin_alega_pag"
    />
    <Route
      path={`${match.url}/fin/instrucoes-negociadores`}
      component={InstrucoesNegociadores}
    />

    <PrivateRoute
      path={`${match.url}/fin/inicio`}
      component={Financeiro}
      codigo="fin"
    />
    <PrivateRoute
      path={`${match.url}/fin`}
      component={Financeiro}
      codigo="fin"
    />

    {/** *********************** CONFIGURAÇÕES ************************************** */}

    <PrivateRoute
      path={`${match.url}/config/treinamento`}
      component={TreinamentoSuporte}
      codigo="conf"
    />

    <PrivateRoute
      path={`${match.url}/config/gerais`}
      component={ConfigGerais}
      codigo="conf_gerais"
    />
    <PrivateRoute
      path={`${match.url}/config/integracoes`}
      component={Integracoes}
      codigo="conf_gerais"
    />
    <PrivateRoute
      path={`${match.url}/config/niveis-acesso`}
      component={NiveisAcesso}
      codigo="conf_niveis"
    />
    <PrivateRoute
      path={`${match.url}/config/status-processo`}
      component={StatusProcesso}
      codigo="conf_status_proc"
    />
    <PrivateRoute
      path={`${match.url}/config/status-ocorrencia`}
      component={StatusOcorrencia}
      codigo="conf_status_hist"
    />
    <PrivateRoute
      path={`${match.url}/config/classificacao-titulo`}
      component={ClassificacaoTitulo}
      codigo="conf_status_hist"
    />
    <PrivateRoute
      path={`${match.url}/config/controle-de-acesso-ip`}
      component={ControleDeAcessoIp}
      codigo="conf_ip_liberado"
    />
    <PrivateRoute
      path={`${match.url}/config/controle-de-acesso-horarios`}
      component={ControleDeAcessoHorarios}
      codigo="conf_horario_acesso"
    />
    <PrivateRoute
      path={`${match.url}/config/politicas-de-senha`}
      component={PoliticaDeSenhas}
      codigo="conf_politica_de_senhas"
    />
    <Route
      path={`${match.url}/config/ldap`}
      component={LDAP}
      //codigo="conf_politica_de_senhas"
    />
    <PrivateRoute
      path={`${match.url}/config/email`}
      component={Email}
      codigo="conf_emails"
    />
    <PrivateRoute
      path={`${match.url}/config/layout-importacao`}
      component={LayoutImportacao}
      codigo="conf_layouts_imp"
    />
    <PrivateRoute
      path={`${match.url}/config/layout-higienizacao`}
      component={LayoutHigienizacao}
      codigo="conf_layouts_hig"
    />
    <PrivateRoute
      path={`${match.url}/config/produtos`}
      component={Produtos}
      codigo="conf"
    />
    <PrivateRoute
      path={`${match.url}/config/automacoes`}
      component={Automacoes}
      codigo="conf"
    />
    <PrivateRoute
      path={`${match.url}/config/inicio`}
      component={Config}
      codigo="conf"
    />
    <PrivateRoute
      path={`${match.url}/config`}
      component={Config}
      codigo="conf"
    />

    {/** *********************** OUTROS ************************************** */}
    <Route path={`${match.url}/acesso-negado`} component={erro401} />

    <Route component={erro404} />
  </Switch>
);

export default withRouter(Routes);
